@import "@/scss/_variables.scss";















































































































.context-item {
  max-width: 300px;
  .name {
    width: 120px;
  }
}
