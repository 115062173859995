@import "@/scss/_variables.scss";





























































































































































































































































































































































































































.vform-text-display {
  white-space: pre-wrap;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  font-size: 1em;
}

.current-sensor-value .box{
  border-width: 2px;
  border-style: solid;
  width: 25px;
}
