@import "@/scss/_variables.scss";
































































































































.meta-field-label {
  font-weight: 600;
  min-width: 150px;
}

.meta-field-value {
  min-width: 200px;
}
