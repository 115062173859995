@import "@/scss/_variables.scss";






































































































































































































































































































.babylon-viewer-export-profile {
  /*position:fixed;
  bottom:0;
  left:0;*/
  width: 90%;
  padding-top: 90%;
  margin: auto;
  z-index: 1500;
  border-radius: 4px;
  -webkit-box-shadow: 6px -7px 6px -5px #2c2b2b;
  box-shadow: 6px -7px 6px -5px #2C2B2BFF;
  border: 1px solid #313131;
  position: relative;

  .babylon {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  &.closed {
    left: -150%;
  }

  &.big {
    width: 600px;
    height: 600px;
  }

  .lasche {
    position: absolute;
    right: -50px;
    top: 25px;
    padding: 5px;
    border-radius: 2px;
    transform: rotate(90deg);
    cursor: pointer;
    background: var(--vform-editor-ui-secondary-color);
    transition: all 300ms ease;
    box-shadow: #0f0f10 5px 5px 6px;

    &:hover {
      background: var(--vform-editor-ui-tertiary-color);
      color: #000;
    }
  }
}

// iPad landscape
@media only screen and (min-device-width: 1024px) and (max-device-width: 1024px) and (min-device-height: 768px) and (max-device-height: 768px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  .export-profile-right, .export-profile-left {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}
