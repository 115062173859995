@import "@/scss/_variables.scss";























































































































































.color-pickr.popup-style {
  position: absolute;
  background-color: var(--vform-editor-ui-tertiary-color);
  padding: 8px;
  padding-right: 25px;
  z-index: 530;
  left: 20px;
  top: 0;

  .hu-color-picker {
    margin-right: 25px;
    min-width: 218px;
  }

  .popup-close {
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 4;
  }
}

.hu-color-picker {
  //also apply the min-width when not in pop-up style, as it affects color pickers used in metafield editor
  min-width: 218px;
}
