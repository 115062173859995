@import "@/scss/_variables.scss";






















































































































































































.answer-number-select {
  background-color: #cccccc;
  border-radius: 10px;
  width: 40px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-weight: 600;

  &.selected {
    background-color: cornflowerblue;
    color: white;
  }

  &.selected-editor-mode {
    background-color: #518000;
    color: white;
    opacity: 0.8;
  }
}

.text-answer-select {
  width: 100%;
}
