@import "@/scss/_variables.scss";





















































































































































































































.qr-code-faker {
  text-transform: uppercase;
  width: 100%;
  padding-top: 100%;
  background-color: var(--vform-editor-layout-secondary-color);
  color: var(--vform-editor-ui-primary-color);
  position: relative;

  .icon, .text {
    text-align: center;
    font-weight: bold;
    width: 80%;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}

.error {
  background-color: var(--vform-error-color);
  color: var(--vform-on-dark-color);
}

.cam-hint {
  font-size: 12px;
  padding-left: 3px;
}

.cam-selector {
  font-size: 13px;
  max-width: 80%;

  option {
    max-width: 100%;
    overflow: hidden;
    word-wrap: break-word;
    break-word: break-all;
  }
}
