@import "@/scss/_variables.scss";








































































































.l-panel-furioos {
  position:fixed;
  top: 25px;
  width: 200px;
  left: 50%;
  transform: translateX(-50%);
}
#containerDivId {
  height: 100vh;
  width: 100vw;
  margin-left: -15px;
  margin-right: -15px;
  overflow: hidden;
  border: 0px solid transparent !important;
  iframe {
    height: 100vh !important;
    border: 0px solid transparent !important;
  }
}
