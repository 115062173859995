@import "@/scss/_variables.scss";
















































.logout-link {
    margin-right:-15px;
    a {
        color: #000 !important;
    }
    &:hover {
        a {
            color:#fff !important;
        }
    }
}

#sfx-user-navigation {
    margin-right:-15px;
    li {
        background-color: rgba(255,255,255,0.2);
        margin-left: 1px;
        margin-right: 1px;
        border-radius: 3px;
      a {
            background-color:transparent;
            border-color:transparent;
            color:#fff;
            margin-right:0;
            span {
                color:#fff;
                display:inline-block;
                padding-top: 2px;
            }
            &::after {
                display:none;
            }
            &:hover, &.router-link-active {
                background-color: rgba(0,0,0,0.5);
                border-color: transparent !important;
            }
        }
    }
    .user-special-link {
        a {
            height: 100%;
            border-radius:2px;
            span {
                display:inline-block;
            }
        }
      .icon {
        font-size: 1.3em !important;
        margin-top: -5px;
      }
    }
    .icon-preview {
        display:inline-block;
        width: 35px;
        position:absolute;
        left: 8px;
        border-radius:50%;
        margin-right: 5px;
        top:50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        z-index:5;
        .square-image {
            border-radius:50%;
        }
    }
}
