@import "@/scss/_variables.scss";








































































































































































































































.answer {
  width: 100px;
}

.correct-answer {
  position: relative;
}

.correct-answers-container {
  margin-bottom: 30px;
}
