/*
--------------------
Utilities
--------------------
*/
.hidden {
  display:none;
}
.full-width {
  width: 100%;
}
.full-width-input {
  input {
    width: 100%;
  }
}
.alert-bg {
  background-color: #f44336;
}
.neutralize-margin-left {
  margin-left: -15px;
}
.full-height {
  height: 100%;
}
.too-long-text-break {
  word-break: break-all;
}
.inline-block {
  display:inline-block;
}
.center-horizontally {
  position:absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.multi-line {
  white-space: pre-wrap;
}

.fancy-scrollbar {
  -ms-overflow-style: scrollbar;
  //&::-webkit-scrollbar { width: 0 !important }
  /* width */
  &::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background-color: #171615;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background-color: $highlight;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background-color: $highlight;
  }
}
.scroll-container {
  @extend .fancy-scrollbar;
  height: 200px;
  overflow-Y: scroll;
  background-color: rgba(0,0,0,0.2);
}
.scroll-container-horizontal {
  @extend .fancy-scrollbar;
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  width: 100%;
  overflow-X: scroll;
  overflow-Y: auto;
  // background-color: rgba(0,0,0,0.2);
}
.collapsible {
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  cursor:pointer;
  &.is-collapsed {
    .content {
      height:0;
      padding-top:0;
      padding-bottom:0;
      overflow:hidden;
    }
    .header {
      .icon {
        -webkit-transform: translateY(-50%) rotate(-90deg);
        transform: translateY(-50%) rotate(-90deg);
      }
    }
  }
  .content {
    padding: 15px;
    background-color: rgba(0,0,0,0.4);
  }
  .header {
    background-color: $info-panel-header-color;
    position:relative;
    padding:25px;
    padding-top:15px;
    padding-bottom:15px;
    &.transparent {
      background-color: rgba(0,0,0,0.6);
    }
    &.slim {
      padding:15px;
      padding-top: 8px;
      padding-bottom: 8px;
    }
    h1,h2,h3 {
      margin-bottom:0;
      padding-bottom:0;
    }
    .icon {
      position:absolute;
      right:15px;
      top:50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      -webkit-transition: all 300ms ease;
      transition: all 300ms ease;
    }
  }
}
.darker {
  background-color: rgba(0,0,0,0.5);
}
.brighter {
  background-color: rgba(255,255,255,0.1);
}
.slightly-darker {
  background-color: rgba(0,0,0,0.2);
}
.lighter {
  opacity: 0.5;
}
.lighter-lighter {
  opacity: 0.3;
}
.dark-grey-text {
  color: #222;
}
.inline-block {
  display:inline-block;
}
.uppercase {
  text-transform: uppercase;
}
.clickable {
  cursor:pointer;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  &.withHoverHighlightBackground:hover {
    background-color: $highlight;
  }
  &.withHoverBrightBackground:hover {
    background-color: $tab-item-background;
  }
  &.withHoverDarkBackground:hover {
    background-color: #191818;
  }
}
.non-clickable {
  pointer-events:none;
}
.clickable-forbidden {
  cursor: not-allowed !important;
}
.clickable-disabled {
  pointer-events:none;
}
.overflow-text {
  position:relative;
  overflow-wrap: break-word;
  max-width: 100%;
  .longtext {
    display:none;
  }
  .longtext {
    position:absolute;
    left:0;
    top:0;
  }
  &:hover {
    .longtext {
      display: inline;
      background: $table-row-hover-background;
      z-index: 5;
    }
    .shorttext {
      opacity:0;
    }
  }
}
.bg-bright {
  background-color: $pagination-background-color;
}

.bg-highlight {
  background-color: $highlight;
  color: #fff;
}
.bg-beige {
  background-color: #7B7872;
}
.bg-secondary {
  background-color: var(--vform-editor-ui-secondary-color);
}

.no-hover {
  pointer-events: none;
}

.smaller {
  font-size: 0.9rem;
}

/*alignments and positionings */
.float-right{
  float: right;
}

.margin-auto {
  margin: auto;
}

.border-radius {
  border-radius: 4px;
}

.no-padding-force {
  padding: 0 !important;
}

.inter-medium {
  font-family: 'Inter medium', sans-serif;
}
.inter-bold {
  font-family: 'Inter bold', sans-serif;
}
.debug-console {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100000;
  background-color: black;
  color: #fff;
  padding: 8px;
}
.pointer-events-none {
  pointer-events: none;
}

.centered-item {
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  position: absolute;
}