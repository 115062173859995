.settings-button, .default-button {
  background-color: var(--vform-editor-ui-secondary-color);
  color: var(--vform-editor-ui-primary-color);
  padding: 3px 12px 2px 12px;
  border-radius: 3px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.8rem;
  cursor: pointer;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  -webkit-box-shadow: 0 8px 6px -5px black;
  box-shadow: 0 5px 6px -5px black;
  z-index: 105;
  position: relative;
  &:hover {
    background-color: var(--ccdefault-normal-button-hover-background);
  }
  &.switched-colors {
    background-color: var(--vform-editor-ui-quinary-color);
    border: 1px solid var(--vform-editor-ui-quinary-color);
    &:hover {
      background-color: var(--vform-editor-ui-secondary-color);
      border: 1px solid var(--vform-editor-ui-quinary-color);
    }
  }
  &.orange-button {
    background-color: var(--vform-editor-accent-color);
    border: 1px solid var(--vform-editor-accent-color);

    &:hover {
      background-color: var(--vform-editor-accent-color-hover);
      border: 1px solid var(--vform-editor-accent-color-hover);
    }
  }

  // language-selector
  .form-switch {
    border: 0 solid transparent;
    color: var(--vform-editor-ui-primary-color);

    .language-selector {
      .selected {
        color: var(--vform-editor-ui-primary-color);
      }
    }
  }
}



.default-button {
  &.accent {
    //background-color: var(--cclight-accent-color);
    background-color: $highlight;
  }
  &:hover {
    color: #fff;
  }
}

.cclight-button {
  background-color: var(--vform-editor-accent-color);

  &:hover {
    background-color: var(--vform-editor-accent-color-hover);
  }
}

.tag-input {
  position:relative;
  &.high-z-index {
    z-index: 200;
  }
}