@import "@/scss/_variables.scss";






































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.right-left-skipper {
  position: fixed;
  top: 50px;
  right: 0;
  z-index: 120;
  background-color: rgba(255, 0, 0, 0.6);
  color: #fff;
  width: 70%;
  min-width: 300px;
}
.video-background {
  z-index: 15;
}

.go-to-project {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-color: var(--vform-editor-ui-secondary-color);
  z-index: 105;
  color: #fff;
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: bold;

  .loading-display {
    margin: auto;
    margin-top: 8px;
  }
}

.slide-out {
  position: absolute;
  top: 0;
  bottom: 15vh; //var(--vform-default-vform-margin);
  right: -30vw; //var(--vform-default-vform-margin);
  background-color: var(--vform-editor-ui-quinary-color);
  color: var(--vform-bottom-bar-text-color);
  width: 30vw;
  height: 90vh;
  z-index: 5555;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;

  .leave-button {
    position: absolute;
    bottom: 15px;
    left: 15px;
  }

  &.open {
    right: 0;
    z-index: 5555;
  }
}

.vform-bottom-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  -webkit-box-shadow: 2px 2px 10px 2px #666;
  box-shadow: 2px 2px 10px 2px #666;
  background-color: var(--vform-editor-ui-bottom-bar-color);
  color: var(--vform-bottom-bar-text-color);
  height: var(--vform-bottom-bar-height);

  .form-name {
    height: var(--vform-bottom-bar-height);
    padding: 15px;
    z-index: 55;
    min-width: 150px;
    font-size: 1.1rem;
    display: flex;

    .text {
      font-weight: bold;
    }
  }

  .vform-view-bottom-buttons {
    font-size: 1.1rem;
  }

  .vform-bottom-icon {
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
    cursor: pointer;
    border-radius: 3px;
    min-width: 40px;
    height: 40px;
    text-align: center;
    background-color: #41475b;
    display: flex;
    justify-content: center;
    align-items: center;

    &.inactive {
      //color: var(--vform-bottom-bar-inactive-color);
      background-color: #32373a;
      color: #474e52;
      cursor: default;
    }
  }

  z-index: 55;
}

@media (hover: hover) {
  .vform-bottom-bar {
    .vform-bottom-icon {
      &:not(.inactive) {
        &:hover {
          background-color: var(--vform-bottom-bar-hover-background-color);
          color: var(--vform-bottom-bar-hover-color);
        }
      }
    }
  }
}

.slideshow {
  width: 100%;
  height: 100vh;
  margin: 0;
  background-color: transparent;
  position: relative;
  overflow: hidden;
  color: #000;

  &.with-slider {
    height: calc(var(--vform-editor-vform-editor-height) - var(--vform-editor-carousel-height));
  }

  &.with-background {
    background-color: var(--vform-editor-ui-secondary-color);
  }
}

#slideshow-inner {
  top: 50%;
  left: 50%;
  transform-origin: top left;
  height: 100%;
  width: 100%;
  position: static;
  display: flex;
  justify-content: center;
}

.version-error {
  width: 100%;
  background-color: #f87606;
  color: #fff;
  z-index: 55;
  position: absolute;
  top: 0;
  left: 0;
}

.vform-viewer-loading-ellipsis {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-color: var(--vform-editor-layout-tertiary-color);
  opacity: 0.8;
  padding: 15px;
  border-radius: 3px;
}

.no-slide-error {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-color: red;
  color: var(--vform-on-dark-color);
  z-index: 15;
}

.cclight-notification {
  background-color: var(--vform-editor-layout-tertiary-color);
}

.step-fade-enter-active {
  transition: all 1.2s ease-in;
}

.step-fade-leave-active {
  transition: all 0.4s cubic-bezier(1, 0.5, 0.8, 1);
}

.step-fade-enter {
  opacity: 0;
}

.step-fade-enter-to {
  opacity: 1;
}

.step-fade-enter-from,
.step-fade-leave-to {
  opacity: 0;
}

.bottom-bar-logo {
  width: 180px;
  display: flex;
  justify-content: center;
  padding: 5px;
}
