@import "@/scss/_variables.scss";

















































































































































































































.language-switch-front {
  position:fixed;
  top:0;
  right:15px;
  z-index:10;
}
.environment {
  position:fixed;
  top:0;
  left:50%;
  -webkit-transform:translateX(-50%);
  transform:translateX(-50%);
  width: 250px;
  height:45px;
  font-size: 1.1rem;
  text-transform:uppercase;
  z-index:500;
  .text {
    position:absolute;
    top:50%;
    left:50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
  }
  .spickel {
    right: -25px;
    left:auto;
    top:0;
    width:50px;
    background-color: $stagingColor;
    -webkit-transform: skew(-26deg, 0deg);
    transform: skew(-26deg, 0deg);
  }
  .spickel2 {
    left: -30px;
    top:0;
    width:50px;
    height:100%;
    background-color: $stagingColor;
    -webkit-transform: skew(-26deg, 0deg);
    transform: skew(-26deg, 0deg);
    position:relative;
  }
  background-color: $stagingColor;
  display:none;
  &.staging {
    display:block;
    background-color: $stagingColor;
    .spickel, .spickel2 {
      background-color: $stagingColor;
    }
  }
  &.demo {
    display:block;
    background-color: $demoColor;
    .spickel, .spickel2 {
      background-color: $demoColor;
    }
  }
}
.connect-info {
  position:fixed;
  bottom:25px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  background: $highlight;
  z-index:108;
  text-transform: uppercase;
  font-size: 0.8rem;
  color: #fff;
  -webkit-box-shadow: 0 8px 6px -5px black;
  box-shadow: 0 5px 6px -5px black;
}
.page-placeholder {
  min-height: 80vh;
}
