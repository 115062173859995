@import "@/scss/_variables.scss";


























































































































































































































































































































































$label-width: 38px; //rotated,that's why height = width
$filter-width: 300px;
$filter-inner-border-color: #444;

.filter-checkbox {
    margin-right: 5px;
}

.filter-label {
    display: inline-block;
    padding: 0.8em;
    position: absolute;
    z-index: 3;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    transform-origin: top right;
    right: 0;
    top: -1px;
    height: $label-width;
    min-width: 150px;
    cursor: pointer;

    .text {
        z-index: 3;
        position: absolute;
        top: 50%;
        right: 45px;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
    }

    .filter-label-svg {
        height: 100%;
        transform-origin: center;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 0;

        .st1 {
            fill: $filter-label-background-color;
        }

        .st0 {
            fill: $standard-border-color;
        }
    }
}

.filter-separator {
    background-color: $filter-inner-border-color;
    margin: 20px 0px;
}

.filter-active {
    input {
        border: 1px solid $highlight;
    }
}
$broomWidth: 35px;
.filter {
    background-color: $filter-background-color;
    position:relative;
    height: auto;
    min-height: 650px;
    border: 1px solid $standard-border-color;
    width: $filter-width;
    margin-right: $label-width + 15px;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
    z-index:8;
    font-size: 0.9rem;
    .broom {
        margin-top: -5px;
        position:relative;
        cursor:pointer;
        background-color: lighten($filter-background-color, 10%);
        -webkit-transition: all 300ms ease;
        transition: all 300ms ease;
        height:$broomWidth;
        width:$broomWidth;
        .icon {
            position:absolute;
            top:50%;
            left:50%;
            -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
        }
        .hover-text {
            position: absolute;
            background-color: $highlight;
            z-index:15;
            width:0;
            overflow:hidden;
            left: $broomWidth;
            height:$broomWidth;
            top:0px;
            -webkit-transition: all 300ms ease;
            transition: all 300ms ease;
            opacity:0;
            span {
                display:block;
                position:absolute;
                padding-left:5px;
                width:100%;
                min-width:100px;
                top:50%;
                left:50%;
                -webkit-transform: translate(-50%,-50%);
                transform: translate(-50%,-50%);
            }
        }
        &:hover {
            background-color: darken($filter-background-color, 5%);
            .hover-text {
                display:block;
                height:$broomWidth;
                width:150px;
                opacity:1;
            }
        }
    }
    &.closed {
        margin-left: - $filter-width;
    }

    h3 {
        text-transform: uppercase;
        padding-bottom: 0.5em;
        border-bottom: 1px solid $filter-inner-border-color;
    }

    .form-text {
        width: 100%;
    }
}

.mini-filter {
    .form-text {
        width: 100%;
    }
}

