.vform-editor-universal-select {
  $height: 26px;
  $border-radius: 4px;

  .form-text {
    height: $height;
    padding: 4px;
    padding-left: 11%;
    border-radius: $border-radius;
  }

  .input-prepend-icon {
    height: 23px;
    border-radius: 3px;
  }
}

.vform-editor-body {
  font-style: normal;
  font-weight: 400;
  font-size: 0.8rem;
}

.vform-editor-body-bold {
  font-style: normal;
  font-weight: 700;
  font-size: 0.8rem;
}

.vform-editor-body-description {
  font-style: italic;
  font-weight: 400;
  font-size: 0.867rem;
  letter-spacing: 0.02em;
}

.vform-editor-button-text {
  font-style: normal;
  font-weight: 700;
  font-size: 0.8rem;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.vform-editor-section-title {
  font-style: normal;
  font-weight: 700;
  font-size: 0.867rem;
  letter-spacing: 0.02em;
  text-transform: uppercase;
}

.vform-editor-label {
  font-style: normal;
  font-weight: 700;
  font-size: 0.667rem;
  letter-spacing: 0.055em;
  text-transform: uppercase;
}

.vform-editor-breadcrumb-level {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 1.133rem;
  letter-spacing: 0.02em;
  text-transform: uppercase;
}

.label-field {
  width: 85%;
  position:relative;
}
.right-of-label-field {
  width: 5%;
}

//extract to globals or check if they dont already exist

.panel-tab {
  -webkit-border-top-left-radius: 4px;
  -webkit-border-top-right-radius: 4px;
  -moz-border-radius-topleft: 4px;
  -moz-border-radius-topright: 4px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color: var(--vform-editor-layout-tertiary-color);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;

  &:hover,
  &.active-column {
    background-color: var(--vform-editor-layout-secondary-color);
  }

  color: var(--vform-editor-ui-primary-color);
}

.scrollbar-container {
  flex-grow: 1;
  overflow: auto;
}

.flexer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
  position: static;

  &.panel-undocked {
    position: fixed;
    top: 200px;
    right: 0;
    z-index: 1000 !important;
    width: 400px;
    max-height: 60vh;
    background-color: var(--vform-editor-layout-tertiary-color);
  }

  &.panel-undocked.slide-right {
    .vform-inline-menu {
      position: absolute;
      left: 400px;
    }
  }

  &.panel-docked {
    position: static !important;
    transform: none !important;
    top: auto !important;
    left: auto !important;
    width: auto !important;
  }
}

.tab-container {
  background-color: var(--vform-editor-layout-tertiary-color);
}

.side-panel {
  background-color: var(--vform-editor-panel-active-background-color);
}

.drag-grip {
  cursor: move !important;
  z-index: 55;
}

.fit-content {
  max-width: fit-content;
}

.dflex {
  display: flex;
  flex-wrap: wrap;
}

.warning-icon {
  color: #da5e06;
}

//v-form specific overrides
.vform-preview .form {
  width: 100%;
  background: var(--vform-editor-panel-active-background-color);
}

.vform-container-2 {

  height: var(--vform-editor-vform-editor-height);
  overflow-y: hidden;
  margin: 0 !important;
  max-width: 100%;
  word-wrap: break-word;

  &.fullHeight {
    max-height: 100vh;
  }
}

.vform-container {
  display: flex;
  flex-direction: column;
  height: var(--vform-editor-vform-editor-height);
  width: 100%;
  background-color: var(--vform-editor-panel-active-background-color);
  padding: 0 !important;
}

.vform-container {
  display: flex;
  flex-direction: column;
  height: var(--vform-editor-vform-editor-height);
  width: 100%;
  background-color: var(--vform-editor-panel-active-background-color);
  padding: 0 !important;
  color: var(--vform-editor-ui-primary-color);

  label.italic {
    font-style: italic;
    font-size: 11px;
  }

  .info-panel {
    .box {
      padding: 0;
    }

    .header {
      border: 0;
      padding: 9px;
      background-color: var(--vform-editor-ui-quinary-color);
      color: var(--vform-editor-ui-primary-color);
    }

    .info {
      padding: 0 !important;
    }
  }

  .info-panel .header h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 0.8rem;
    letter-spacing: 0.05em;
    text-transform: revert;
  }

  h3 {
    font-size: 12px;
    font-weight: 700;
    margin-top: 5px;
    color: #d4d4d5;
  }

  .form-error {
    width: 100%;
    max-width: 100%;
  }

  .fancy-scrollbar {
    /* Track */
    &::-webkit-scrollbar-track {
      background-color: var(--vform-editor-panel-active-background-color);
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background-color: #5b5b5b;
      border: solid 1px #5b5b5b;
      border-radius: 5px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background-color: #5b5b5b;
      border: solid 1px #5b5b5b;
      border-radius: 5px;
    }
  }

  .custom-select {
    margin: 0 0 0 0 !important;
    font-size: 13px;
    border-radius: 4px;
  }

  .vform-element-actions {
    float: right;
    display: flex;
    color: #d4d4d5;
  }
}

.form-elements-container {
  background: var(--vform-editor-panel-active-background-color);
}

.vform-element {
  .icon {
    cursor: pointer;
  }

  label {
    color: var(--vform-editor-ui-primary-color);
  }
}

.form-text-dark {
  background-color: var(--vform-editor-ui-quaternary-color) !important;
  color: var(--vform-editor-ui-primary-color) !important;
  border-color: var(--vform-editor-ui-secondary-color) !important;
  border-radius: 4px;
  font-size: 0.8rem;
  font-weight: 400;
  padding: 4px !important;
  min-height: 25px;
  max-height: 25px;
}

textarea.form-text-dark {
  min-height: 70px;
  max-height: none;
}

/* Orange checkboxes */
.container {
  display: block;
  position: relative;
  padding-left: 30px !important;
  margin-bottom: 10px;
  cursor: pointer;
  font-size: 0.875rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  line-height: 21px;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 21px;
  width: 21px;
  background-color: var(--vform-editor-ui-quaternary-color);
  border: solid 1px;
  border-radius: 4px;
  border-color: var(--vform-editor-ui-secondary-color);

  &.disabled {
    background-color: var(--vform-editor-ui-tertiary-color);
    cursor: not-allowed;
  }
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  // background-color: black;
}

/* When the checkbox is checked, add a blue background */
input:checked ~ .checkmark {
  background-color: #172445;
}

/* When the checkbox is checked, add a blue background */
#slideshow-div input:checked ~ .checkmark {
  background-color: var(--vform-editor-layout-accent-color);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid #fff;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* Style the checkmark/indicator */
#slideshow-div .checkmark:after {
  border-color: var(--vform-editor-emphasized-font-color);
}

/* create custom radio button */
.radio {
  position: absolute;
  top: 0;
  left: 0;
  height: 21px;
  width: 21px;
  background-color: var(--vform-editor-ui-quaternary-color);
  border: solid 1px var(--vform-editor-ui-secondary-color);
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .radio {
  // background-color: black;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .radio {
  background-color: #172445;
}
/* When the checkbox is checked, add a blue background */
#slideshow-div .container input:checked ~ .radio {
  background-color: var(--vform-editor-layout-accent-color);
}

/* Create the checkmark/indicator (hidden when not checked) */
.radio:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .radio:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .radio:after {
  top: 4px;
  left: 4px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  border: solid #fff;
}

/* Style the checkmark/indicator */
#slideshow-div .container .radio:after {
  border: solid var(--vform-editor-emphasized-font-color);
}

//new style - last version
.vform-editor-label.section {
  margin-top: 30px;
}

.icon-delete-option {
  color: var(--vform-editor-ui-secondary-color);
  position: absolute;
  right: 0;
  top: 0;
  display: inline-block;

  .icon {
    display: inline-block;

    svg {
      vertical-align: top;
    }
  }
}

.superIndex {
  z-index: 556 !important;
}

@media (max-width: 1070px) {
  .label-field {
    width: 70%;
  }
}