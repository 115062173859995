@import "@/scss/_variables.scss";


































































































































.year {
  font-size: 1.3rem;
  font-weight: bold;
  margin-bottom: 8px;
}
.year-section {
  background-color: var(--vform-editor-ui-quinary-color);
  color: var(--vform-editor-ui-primary-color);
  height: 100%;
}
.month-label {
  display: inline-block;
  min-width: 110px;
}
