/*
--------------------
Navbars
--------------------
*/
.nav-link {
  cursor:pointer;
}
.nav-bar {
  //background-color: $navbarcolor;
  background: rgb(14,15,15);
  background: linear-gradient(0deg, #1d1d1f 0%, rgb(37, 42, 54) 100%);
  border-bottom: 1px solid $tab-border-color;

  ul {
    width: 100%;
    list-style: none;
    text-transform: uppercase;
    margin-bottom: 0;
    padding-left:0;
    & > li > a {
      border-top-left-radius: 2px;
      border-top-right-radius: 2px;
      margin-right: 8px;
      display: inline-block;
      padding: 4px 18px;
      background-color: $nav-item-background;
      -webkit-transition: all 400ms ease;
      transition: all 400ms ease;
      border: 1px solid $nav-item-background;
      color: $nav-item-text-color;
      position:relative;
      span {
        position:relative;
        z-index:1;
      }
      &::after {
        content: '';
        width: 100%;
        height:100%;
        position:absolute;
        bottom:0;
        background-color: $nav-item-background;
        left:0;
        z-index:0;
      }
      .icon {
        display: inline-block;
        margin-right: 3px;
      }

      &.router-link-active, &.active, &:hover {
        background-color: $nav-item-background-active;
        color: $nav-item-text-color-active;

        &::after {
          bottom: -4px;
          height:110%;
          background-color: $nav-item-background-active;
        }
      }
      &.active {
        margin-bottom: -2.5px;
      }

    }
  }
}
#sfx-user-navigation {
  li a {
    height:$user-nav-height;
    &.router-link-active, &:hover {
      border: 1px solid $nav-item-background;
      &::after {
        display:none;
      }
    }
  }
}

/*
--------------------
Tabs
--------------------
*/
.tabs {
  width: 100%;
  list-style: none;
  text-transform: uppercase;
  margin-bottom: 0;
  padding-left: 0;
  display:block;
  position:relative;
  z-index:1;
  li:nth-child(1) {
    .pre-arrow {
      display:none;
    }
  }
  li {
    position: relative;
    margin-right: 8px;
    z-index:3;
    display:inline-block;
    &.arrowStyle {
      margin-right: 14px;
    }

    .nav-link { //can be a, but alo just a div
      display: inline-block;
      padding: 4px 8px;
      background-color: $tab-item-background;
      -webkit-transition: all 400ms ease;
      transition: all 400ms ease;
      border: 1px solid $tab-item-background;
      color: $tab-item-text-color;
      font-size: 0.9em;
      position: relative;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;

      .arrow {
        position:absolute;
        right:-13px;
        top:0;
        bottom:auto;
        z-index:5;
        width: 0;
        height: 0;
        border-top: 13px solid transparent;
        border-bottom: 13px solid transparent;
        border-left: 13px solid $tab-item-background;
      }
      .pre-arrow {
        position: absolute;
        left: -14px;
        top: -1px;
        border-top: 14px solid $tab-item-background;
        border-bottom: 14px solid $tab-item-background;
        border-left: 14px solid transparent;
      }
      span {
        position: relative;
        z-index: 1;
      }
      &::after {
        content: '';
        width: 100%;
        left: 0;
        bottom: 0;
        position: absolute;
        height: 100%;
        background-color: $tab-item-background;
        z-index: 0;
      }
      &.active:not(.inactive), &.router-link-active:not(.inactive) {
        background-color: $tab-item-background-active;
        color: $tab-item-text-color-active;
        border-bottom: 1px solid $tab-item-background-active;
        //border-top: 1px solid #181B1F;
        .arrow {
          border-left: 13px solid $tab-item-background-active;
        }
        .pre-arrow {
          border-top: 14px solid $tab-item-background-active;
          border-bottom: 14px solid $tab-item-background-active;
        }

        &::after {
          bottom: -4px;
          height: 110%;
          background-color: $tab-item-background-active;
        }
      }
      &:hover:not(.disabled) {
        background-color: $tab-item-background-active;
        color: $tab-item-text-color-active;
        border-bottom: 1px solid $tab-item-background-active;

        &::after {
          bottom: -4px;
          height: 110%;
          background-color: $tab-item-background-active;
        }
      }
    }
  }
}
.sub-sub-panel-background .tabs {
  border-bottom: 1px solid $sub-sub-panel-tab-border-color;
  li {
    .nav-link { //can be a, but alo just a div
      background-color: $sub-sub-panel-tab-border-color;
      &::after {
        background-color: $sub-sub-panel-tab-border-color;
      }
      &.active, &.router-link-active, &:hover {
        background-color: $sub-sub-panel-background-color;
        color: $tab-item-text-color-active;
        border-bottom: 1px solid $sub-sub-panel-background-color;
        border-top: 1px solid $sub-sub-panel-tab-border-color;
        border-left: 1px solid $sub-sub-panel-tab-border-color;
        border-right: 1px solid $sub-sub-panel-tab-border-color;
        &::after {
          background-color: $sub-sub-panel-background-color;
        }
      }
    }
  }
}
.backbutton .tabs {
  li {
    float: right;
  }
}
