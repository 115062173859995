@import "@/scss/_variables.scss";


































































































.year {
  font-size: 1.3rem;
  font-weight: bold;
}
.year-section {
  color: #000;
  background-color: #efefef;
}
.orgname {
  width: 150px;
  padding: 3px;
}
.user-view {
  height: 200px;
  overflow-Y:scroll;
  width: 150px;
}
