@import "@/scss/_variables.scss";




























































































































































.dropzone-new-style {
  width: 100%;
  padding: 15px;
  background-color: #3B434D;
  border-radius: 4px;

  .inner {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='15' ry='15' stroke='white' stroke-width='2' stroke-dasharray='6%2c 11' stroke-dashoffset='31' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 15px;
    min-height: 200px;
    padding: 15px;
    position: relative;
  }

  .dropzonejs {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    padding: 8px;
  }

  .instruction {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}
