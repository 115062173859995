@import "@/scss/_variables.scss";





















































































.form-text-dark:disabled{
  background-color: var(--vform-editor-ui-secondary-color) !important;
}
.screen-size-selector {
  .vform-custom-select {
    font-size: 13px;
    border-radius: 4px;
    background-color: var(--vform-editor-ui-secondary-color);
    border-color: var(--vform-editor-ui-secondary-color);
    color: var(--vform-editor-ui-primary-color);
    width: 130px;
  }
}
.scape-chooser {
  padding: 3px;
  margin-right:3px;
  width: 40px;
  height: 25px;
  border-radius: 4px;
  background-color: var(--vform-editor-ui-secondary-color);
  color: var(--vform-editor-ui-tertiary-color);
  position:relative;
  cursor:pointer;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
  }
  &.selected, &:hover {
    background-color: var(--vform-editor-ui-tertiary-color);
    color: var(--vform-editor-ui-secondary-color);
  }
}
.pixels {
  width: 60px !important;
}
