@import "@/scss/_variables.scss";




















































#language-switch {
  background-color: rgba(255, 255, 255, 0.2);
  display: inline-block;
  border-radius: 3px;

  .custom-select {
    cursor: pointer;
    background-color: transparent;
    color: #fff;
    height: $user-nav-height;
    padding-left: 8px;

    &:focus {
      box-shadow: none;
    }
  }

  &.darkOnBright {
    background-color: #fff;
    color: #000;

    .custom-select {
      color: #000;
    }
  }
}
