@import "@/scss/_variables.scss";






















































































































































































































































































































































































































































































































































































































.row-delete-button {
  margin-top: 3px;
}
.center-vertical {
  margin-top: auto;
  margin-bottom: auto;
}

label {
  display: block;
}

.cell {
  width: 400px;
  display: block;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
}

.table-row .cell {
  display: flex;
}

.row {
  display: flex;
  margin: 10px 0 0 0;
  width: 100%;
}

.select {
  height: 40px;
  width: 100%;
  margin-bottom: 8px;
}

div.content {
  background-color: red !important;
}

.dirty {
  background-color: #ddd;
}

.col-5 {
  padding: 0px;
}

.source-title {
  background-color: var(--vform-editor-ui-secondary-color);
  padding: 5px;
  border-radius: 2px;
}

.error {
  max-width: 100%;
}

.table-header {
  background-color: var(--vform-editor-ui-secondary-color);
  padding: 5px;
}

.table-row {
  display: flex;
  background: #222;
  padding: 5px;
}

.flex-right {
  justify-content: right;
  display: flex;
}
