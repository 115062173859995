@import "@/scss/_variables.scss";

















































































































































































































































































































































































































































































































#panel-contextmenu {
  font-size: 0.8rem;
  position: fixed;
  z-index: 100000;
  background-color: var(--vform-editor-ui-secondary-color);
  color: var(--vform-editor-ui-primary-color);
  border-radius: 3px;
  -webkit-box-shadow: 0 8px 6px -5px black;
  box-shadow: 0 5px 6px -5px black;
  .cat-name {
    font-size: 0.9rem;
  }
  .item {
    cursor: pointer;
    padding: 2px;
    padding-left: 30px;
    position: relative;

    .icon {
      position: absolute;
      left: 5px;
    }

    &:hover {
      background-color: var(--vform-editor-ui-quinary-color);
    }
    &.disable-hover:hover {
      background-color: var(--vform-editor-ui-secondary-color);
    }
    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}
