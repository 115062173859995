@import "@/scss/_variables.scss";




























.info-helper {
  display: inline-block;
  padding-top: 5px;
  position: relative;
  z-index: 0;
  cursor: pointer;

  .tooltip {
    min-width: 370px;
    position: absolute;
    background-color: $info-helper-background-color;
    pointer-events: none;
    overflow: auto;
    padding: 10px;
    .img {
      max-width: 100%;
    }
  }

  &:hover {
    z-index: 500;
    .tooltip {
      opacity: 1;
    }
  }
}
