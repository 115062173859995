@import "@/scss/_variables.scss";














































































































































































































//transition
.toggle-enter-active, .toggle-leave-active {
    transition: all .3s ease-out;
    height: 80px;
    overflow:visible;
}
.toggle-enter, .toggle-leave-to {
    height: 0;
    overflow:hidden;
}

.data-row-delete {
    cursor:pointer;
    opacity:0.8;
    &:hover {
        opacity:1;
    }
}
.table-filler {
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
    position:relative;
    .icon {
        -webkit-transition: all 300ms ease;
        transition: all 300ms ease;
        opacity: 0;
        position:absolute;
        right: 6px;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        font-size: 0.8em;
    }
    &:hover {
        .icon {
            opacity: 1;
        }
    }
}
.row-lifetime {
  position:relative;
  z-index:5;
  .hover {
    position:absolute;
    opacity: 0;
    pointer-events:none;
    width:340px;
    background: $input-background-color;
    z-index: 5;
  }
  &:hover {
    .hover {
      opacity: 1;
    }
  }
}
