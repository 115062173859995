@import "@/scss/_variables.scss";



























































textarea.form-text{
  min-height: 200px;
}
