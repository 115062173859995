@import "@/scss/_variables.scss";












































































































































































































































































































































































































































































































































































































































































































































































































.chart-legend {
    ul {
        text-align: left;
        li {
            color: #000;
            font-weight:bold;
            display:inline-block;
            margin-right:10px;
            margin-bottom: 10px;
            list-style:none;
            position:relative;
            padding: 8px 8px 8px 25px;
            max-height: 140px;
            overflow:auto;
            border: 1px solid rgba(0,0,0,0.2);
            background-color: rgba(0,0,0,0.1);
            div {
                width: 15px;
                height: 15px;
                position:absolute;
                left:5px;
                margin-top:1px;
                top:50%;
                -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
            }
        }
    }
    //styles for canvasToPDF-printing
    &.print {
        ul {
            margin-bottom:0;
            li {
                background-color: transparent;
                border: 0 transparent;
                font-family: 'Helvetica', 'sans-serif';
                font-weight: normal;
                margin:0;
                display:block;
                div {
                    margin-top:0;
                }
            }
        }
    }
}
#chartjs-tooltip {
    max-width: 400px;
    border: 0 transparent !important;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
    tr, th, td, thead, tbody, table, div {
        border: 0 transparent !important;
    }
    background-color: rgba(0,0,0,0.5);
    margin-top: 10px;
    thead {
        background-color: rgba(0,0,0,0.4);
    }
    .label {
        font-weight:bold;
    }
    &::before {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 6px 10px 6px 0;
        border-color: transparent rgba(0,0,0,0.8) transparent transparent;
        position:absolute;
        top:10px;
        left:-10px;
    }
}
/*
---------------
CHARTS
----------------
*/
.chart-font-size {
    font-size: 14px;
}

@media (min-width: 1400px) {
    .chart-font-size {
        font-size: 16px;
    }
}

