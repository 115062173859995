@import "@/scss/_variables.scss";






































































































































































































































































$right-section-width: 110px;
.export-profile-instance {
  background: var(--vform-editor-ui-secondary-color);
  -webkit-box-shadow: 0 8px 6px -5px black;
  box-shadow: 0 5px 6px -5px black;
  padding: 0 15px;
  padding-right: $right-section-width;
  position:relative;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  .info {
    word-wrap: break-word;
  }
  .header {
    background-color: rgba(0,0,0,0.18);
    border-bottom: 1px solid rgba(0,0,0,0.1);
    padding-bottom: 2px;
  }
}
.dark-section-right {
  background: var(--vform-editor-ui-secondary-color);
  border-left: 1px solid rgba(255,255,255,0.1);
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  height: 100%;
  position:absolute;
  right: 0;
  top:0;
  width: $right-section-width;
  text-align: center;
  padding:8px;
  .text {
    top: 50%;
    left: 50%;
    position:absolute;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    width: 90%;
    text-align: center;
    font-size: 0.7rem;
  }
}
.instance-delete-button, .files {
  width: 13px;
  height: 18px;
  position:relative;
  .icon {
    transition: all 300ms ease;
    opacity: 0.5;
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    &:hover {
      opacity: 1;
    }
  }
}

.export-instance-download {
  cursor: pointer;
  &:hover {
    .icon {
     margin-bottom: -5px;
    }
  }
}
