@import "@/scss/_variables.scss";


































































































































































































































































































.vform-dropdown {
  font-size: 0.8rem;
}
