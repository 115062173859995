@import "@/scss/_variables.scss";






















































































































































































































.lazy-node-material {
  z-index: 500;
  background-color: var(--vform-editor-ui-secondary-color);
  -webkit-box-shadow: 8px 8px 6px -5px black;
  box-shadow: 8px 5px 6px -5px black;
  &.popup {
    position: absolute;
    top: -30px;
    right: -600px;
    width: 600px;
    min-height: 300px;
  }
}
