@import "@/scss/_variables.scss";





















































































































































































.video-container-outer {
  position: relative;
  width: 100%;
  z-index:0;
  .video-container {
    width: 100%;
    padding-top: 57.25%;
    position: relative;
    overflow: hidden;
    border-radius: 2px;
  }
  .video, iframe {
    border-radius: 4px;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 1;
  }
  .special-image {
    border-radius: 4px;
    width: 100%;
    height:100%;
    z-index: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    .loading-spinner-widget {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }
  }
}
.video-preview-block {
  width: 100%;
}
