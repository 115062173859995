@import "@/scss/_variables.scss";













































































.manual {
  width: 60px;
}

.reset-button {
  color: var(--vform-editor-ui-tertiary-color);

  &:hover {
    color: var(--vform-editor-ui-primary-color);
  }
}
