@import "@/scss/_variables.scss";

































































































































































































































































































































































































































































































































































































































.tab-item {
  padding: 15px;
  transition: all 300ms ease;
}
.filter-tab {
  padding: 5px 10px;
  margin-right: 3px;
  border-radius: 4px;
  margin-top: 5px;
  cursor: pointer;
  background-color: var(--vform-editor-ui-tertiary-color);
  color: #000;
  z-index: 0;
  font-size: 0.8rem;
  font-weight: bold;
  transition: all 300ms ease;

  &:hover, &.active {
    background-color: var(--vform-editor-ui-secondary-color);
    color: #fff;
  }
}

.tab-view {
  background-color: var(--vform-editor-ui-secondary-color);
  padding: 5px;
  margin-top: -3px;
  z-index: 1;
  position: relative;
}

.search-field {
  width: 200px;
}
.include-exclude {
  max-width: 200px;
}
.mod-selector {
  width: 200px;
  display: inline-block;
}
.form-text-dark {
  margin: 0;
  padding: 0;
  font-size: 0.8rem;
}
