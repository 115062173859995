@import "@/scss/_variables.scss";




































































































































.info-panel {
  .meta-set-attacher {
    .custom-select {
      background-color: $tag-background-dark;
      color: #fff;
    }
  }
}
