@import "@/scss/_variables.scss";



































































































































































.display-checkboxes-wrapper {
  .checkmark{
    background-color: transparent;
  }
}
.vform-checkbox-label-text {
  font-size: 1em !important;
}
