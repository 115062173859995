@import "@/scss/_variables.scss";
















































































































































































































































.carousel-wrapper {
  overflow: visible;
}

.carousel {
  background: var(--vform-editor-layout-secondary-color);
  z-index: 100;
  overflow-x: scroll;
  position: relative;
  width: 100%;
  // please do not remove the following line, it is needed by the slide-show-display to calculate its height
  height: var(--vform-editor-carousel-height);
}

.fancy-scrollbar {
  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    opacity: 0.8;
  }
}

.separator-line {
  background: var(--vform-editor-ui-quinary-color);
  height: 2px;
  position: relative;
  top: 30px;
  width: 100%;
}

.step-title-tooltip {
  position: absolute;
  right: -20px;
  bottom: 137px;
  background: var(--vform-editor-ui-quaternary-color);
  border: solid 1px var(--vform-editor-ui-quaternary-color);
  border-radius: 3px;
  color: var(--vform-editor-ui-primary-color);
  height: 50px;
  width: 190px;
  z-index: 103;
  opacity: 0.8;
  overflow: hidden;
}

.tooltip-active {
  background: var(--vform-editor-accent-color);
  border-top: solid 1px var(--vform-editor-accent-color);
  border-radius: 3px;
  height: 25px;
}

.triangle-down {
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 14px solid var(--vform-editor-ui-quaternary-color);
  position: absolute;

  &.bubble-step-name {
    bottom: 123px;
    z-index: 104;
    opacity: 0.8;
  }

  &.bubble-new-step {
    bottom: 98px;
    z-index: 104;
    opacity: 0.8;
  }
}

.new-step-tooltip {
  position: absolute;
  bottom: 110px;
  background: var(--vform-editor-ui-quaternary-color);
  border: solid 1px var(--vform-editor-ui-quaternary-color);
  color: var(--vform-editor-ui-primary-color);
  border-radius: 3px;
  height: 30px;
  width: 80px;
  z-index: 104;
  text-align: center;
  opacity: 0.88;
}
