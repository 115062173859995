@import "@/scss/_variables.scss";





















































































































.pagination {
    ul {
        margin:auto;
        display: flex;
        padding: 5px;
        li {
            list-style:none;
            cursor:pointer;
            min-width:2em;
            text-align: center;
            line-height: 1rem;
            font-size: 0.9rem;
            &:hover, &.active {
                color: $highlight;
                font-weight: bold;
            }
        }
    }
}
