@import "@/scss/_variables.scss";





























































.mode-selection b {
  font-family: 'Inter bold', sans-serif;
}
.bg-darker {
  background-color: var(--vform-editor-ui-secondary-color);
}
