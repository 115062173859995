@import "@/scss/_variables.scss";




























































































































































.meta-data-upload {
  max-width: 400px;
}
