@import "@/scss/_variables.scss";






































.label-with-icon {
  background-color: rgba(0,0,0,0.2);
  border-radius: 3px;
  font-size: 1rem;
  padding: 5px 12px;
  word-wrap: break-word;
  .vform-label {
    font-weight: bold;
    color: #91929d;
  }
}
.value {
  color: #fff;
}
