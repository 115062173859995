@import "@/scss/_variables.scss";



























































































































































.regex-item {
  input {
    max-width: 500px;
  }
}
