/**
Loading ellipsis
*/

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

/*
--------------------
Loading screen/loading-spinner
--------------------
*/
.loading-screen {
  position:fixed;
  top:50%;
  left:50%;
  -webkit-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
}
.inapp-loading-screen {
  position:relative;
  width: 100%;
  height:100%;
  background-color: var(--vform-editor-ui-secondary-color);
  @-webkit-keyframes MOVE-BG {
    from {
      -webkit-transform: translateX(0);
    }
    to {
      -webkit-transform: translateX(46px);
    }
  }

  @keyframes MOVE-BG {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(46px);
    }
  }


  .loading {
    height: auto;
    text-align: center;
    color: #4c3f2e;
    position: relative;
    overflow: hidden;
    padding: 1rem;
    margin: 3%;
    font-style: italic;
  }

  .bg-container {
    width: 60%;
    overflow: hidden;
    height: 40px;
    position:relative;
  }
  .bg {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 0;

    background: repeating-linear-gradient(
                    -55deg,
                    var(--vform-editor-ui-secondary-color) 1px,
                    #34323a 1px,
                    #2d2c33 3px,
                    var(--vform-editor-ui-secondary-color) 6px,
                    var(--vform-editor-ui-secondary-color) 8px
    );

    -webkit-animation-name: MOVE-BG;
    -webkit-animation-duration: .6s;
    -webkit-animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;

    animation-name: MOVE-BG;
    animation-duration: .6s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }

}
.half-circle-spinner, .half-circle-spinner * {
  box-sizing: border-box;
}
.half-circle-spinner {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  position: relative;
}
.half-circle-spinner .circle {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  border: calc(20px / 10) solid transparent;
}
.loading-screen .half-circle-spinner {
  width: 60px;
  height: 60px;
  .circle {
    border: calc(60px / 10) solid transparent;
  }
}
.half-circle-spinner {
  .circle.circle-1 {
    border-top-color: $highlight;
    animation: half-circle-spinner-animation 1s infinite;
  }
  .circle.circle-2 {
    border-bottom-color: $highlight;
    animation: half-circle-spinner-animation 1s infinite alternate;
  }
  &.dark {
    .circle.circle-1 {
      border-top-color: #171615;
    }
    .circle.circle-2 {
      border-bottom-color: #171615;
    }
  }
  &.white {
    .circle.circle-1 {
      border-top-color: #fff;
    }
    .circle.circle-2 {
      border-bottom-color: #fff;
    }
  }
}
.loading-spinner-widget {
  &.big {
    width: 60px;
    height: 60px;
    .circle {
      border-width: calc(60px / 10);
    }
  }
}
@keyframes half-circle-spinner-animation {
  0% {
    transform: rotate(0deg);

  }
  100%{
    transform: rotate(360deg);
  }
}