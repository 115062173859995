@import "@/scss/_variables.scss";






































































































































































































.small-thumbnail {
    width: 40px;
}
.default-imageDix {
    opacity: 0.3;
}

