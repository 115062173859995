@import "@/scss/_variables.scss";






































































































.image-edit-toggler {
    position: absolute;
    right: 25px;
    top: 15px;
    font-size: 1.3em;
    padding: 6px 8px 6px 10px;
    background-color: $highlight;
    cursor: pointer;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;

    &:hover {
        background-color: darken($highlight, 10%);
    }
}
.preview {
    position:relative;
    .square-image {
        background-color: #171615;
    }
    .selectLinking {
        cursor:pointer;
        position:absolute;
        top:0;
        right:0;
        z-index:3;
        div {
            padding:5px 8px;
            background-color: rgba(255,255,255,0.8);
            color:#000;
            -webkit-transition: all 300ms ease;
            transition: all 300ms ease;
            &.selected, &:hover {
                background-color: $highlight;
                color:#fff;
            }
        }
    }
}

.qr-code-download{
  width: fit-content;
}
