/*
--------------------
Form Elements
--------------------
*/
.custom-select {
  background-color: $nav-item-background;
  color: #000;
  border: 0 transparent;
  padding: 2px 6px;
  padding-right: 26px;
  height: auto;
  position:relative;
  &.dark {
    background-color: $info-panel-header-color;
    color: #fff;
    background-image: url("data:image/svg+xml;charset=utf-8,<svg class='white-text' xmlns='http://www.w3.org/2000/svg' height='1em' viewBox='0 0 448 512'><path fill='white' stroke='white' d='M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z'/></svg>");
  }
  &.darker {
    background-color: $input-background-color-darker;
    color: #fff;
  }
  &.narrow {
    width: auto;
  }
  &.standard-height {
    padding: 9px 25px 9px 9px;
  }
  &.new-style {
    background: var(--vform-editor-ui-secondary-color);
    &.darker {
      background: var(--vform-editor-ui-quinary-color);
    }
  }
}
.orange-switch {
  background-color: $highlight;
  display:block;
  max-width: 200px;
  border-radius: 3px;
  .custom-select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    cursor:pointer;
    background-color: transparent;
    color: #fff;
    padding: 4px 8px;
    padding-right:24px;
    height: 100%;
    option {
      background-color: $navbarcolor;
      color: #fff;
    }
    &:focus {
      box-shadow:none;
    }
  }
}
.form-section {
  border-bottom:1px solid lighten($panel-background-color, 10%);
  padding-top:25px;
  padding-bottom:25px;
  &:last-child {
    border-bottom: 0px;
  }
}
//default form background/border/height for input fields
.form-text, .vdatetime-input {
  background-color: $input-background-color-darker;
  border: 1px solid #57575f;
  height: $input-default-height;
  width: 100%;
  color: #fff;
  padding: $text-input-padding;
  margin-top:0;
  margin-bottom: 0;
  &:focus {
    box-shadow:none;
    outline:none;
  }
  &:-webkit-autofill,
  &:-internal-autofill-selected,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    background-color:$input-background-color-darker !important;
    -webkit-box-shadow: 0 0 0 30px $input-background-color-darker inset !important;
    color: #fff !important;
    outline:none;
    -webkit-text-fill-color: #fff !important;
  }
}
.content-filter-bright {
  font-family: 'Inter', sans-serif;
  .form-dropdown-box {
    background-color: #e7e7e7 !important;
  }
  .default-text-input {
    background-color: #e7e7e7 !important;
    color: #000 !important;
    border: 0 !important;
    border-radius: 2px !important;
    //padding-top: 8px !important;
    //padding-bottom: 8px !important;
    padding-left: 8px !important;
    font-size: 0.9rem !important;
  }
}
input.copy {
  background-color: transparent;
  color: #fff;
  border-color: transparent;
  min-width: 520px;
  padding-left:0;
  &:focus, &:focus-visible {
    outline: none;
  }
}
textarea.form-text {
  min-height: $input-default-height + 40px;
}
//makes the slideout-part of a dropdown-box like you get when you try to add a tag
.form-dropdown-box {
  background-color: #191818;
  padding:20px 15px;
  position: absolute;
  top: 28px;
  z-index:105;
  width: 100%;
  -webkit-box-shadow: 4px 4px 6px -5px black;
  box-shadow: 4px 4px 6px -5px black;
  &.separate-box {
    margin-top: 15px;
  }
  &.table-display {
    .even {
      background-color: $table-row-even-background;
    }
    .odd {
      background-color: $table-row-odd-background;
    }
  }
}
input[type="text"]:disabled, textarea:disabled {
  background-color: #000;
  &::placeholder {
    color: #444;
    text-decoration: line-through;
  }
}
.form-textarea {
  background-color: #191818;
  border: 1px solid #7B7872;
  width: 100%;
  color: #fff;
  padding: $text-input-padding;
}
//todo: could be rendundant, use form-section instead?
.form-group {
  position: relative;
  input[type="checkbox"] {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 0px;
  }
}
.custom-checkbox {
  width: 20px;
  height: 20px;
  position:relative;
  background-color: darken($tag-background-color, 10%);
  .inner {
    opacity:0;
    width: 80%;
    height:80%;
    border-radius: 2px;
    font-size: 0.8em;
    background-color: $highlight;
    position:absolute;
    top:50%;
    left:50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    text-align: center;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
  }
  &.active, &:hover {
    .inner {
      opacity:1;

    }
  }
}

/*
--------------------
Form Input with integrated Prepend/Append Icon
--------------------
*/
//if you want included add-buttons in a e.g. text input (like add new tag), you need to make a form-item as a container
.form-item {
  position:relative;
  &.with-append-icon {
    .form-text {
      width:100%;
      padding-right: 23px + 10px !important;
      margin-top:0;
    }
  }
  &.with-prepend-icon {
    .form-text {
      width:100%;
      padding-left: 23px + 10px !important;
      margin-top:0;
    }
  }
}
.input-prepend-icon {
  position:absolute;
  left:1px;
  top:1px;
  height: 23px;

  background-color: rgba(255,255,255,0.2);
  //border: 1px solid rgba(255,255,255,0.1);
  //border-right:0px;
  width: 24px;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  border-radius: 3px;
  &.no-border {
    border: 0;
  }
  &.no-background {
    background-color: transparent;
  }
  .icon {
    display:block;
    position:absolute;
    top:50%;
    left:50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    margin-top: 1px;
    //color: rgba(255,255,255,1);
  }
  &.interactive {
    cursor:pointer;
    &:hover, &.active {
      background-color: $highlight;
    }
  }
}
.input-append-icon {
  position:absolute;
  right:1px;
  top:1px;
  height: 23px;
  background-color: rgba(255,255,255,0.2);
  border: 1px solid rgba(255,255,255,0.1);
  width: 24px;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  border-radius: 3px;
  .icon {
    display:block;
    position:absolute;
    top:50%;
    left:50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    margin-top: 1px;
  }
  &.interactive {
    cursor:pointer;
    &:hover, &.active {
      background-color: $highlight;
    }
  }}

label.low-margin-bottom {
  margin-bottom: 1px;
}

.form-text-dark {
  &.bigger {
    font-size: 1rem;
  }
}

/*
--------------------
Form Errors/Messages
--------------------
*/
.form-error {
  padding: 4px 8px;
  border-radius: 3px;
  color: #fff;
  background-color: #B45300;
  margin-bottom: 15px;
  max-width:300px;
  animation: fadeInFormError 1s ease-out;
}
.form-group--error {
  border: 1px solid #B45300;
}
.form-small-message {
  color: $highlight;
}
@keyframes fadeInFormError {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}