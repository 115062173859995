@import "@/scss/_variables.scss";





























.separator {
  width: 100%;
  min-height: 15px;
  position: relative;
  .separator-line {
    position: absolute;
    width: 100%;
    height: 2px;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  &.edit-mode {
    background-color: #e5e5f7;
    opacity: 0.8;
    background: repeating-linear-gradient( -45deg, var(--vform-editor-logo-color), var(--vform-editor-logo-color) 1px, #e5e5f7 1px, transparent 5px );
  }
}
