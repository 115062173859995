@import "@/scss/_variables.scss";





























































































.cookie-notification {
  background-color: $highlight;
  width: 400px;
  height:140px;
  position:fixed;
  bottom:0;
  right:30vw;
  z-index:100;
  .close {
    position:absolute;
    top: 5px;
    right:5px;
    z-index:4;
  }
}
h1.front {
  font-size: 3rem;
  font-family: 'DIN Pro Bold', sans-serif;
  margin-bottom:2rem;
}
h2.front {
  font-size: 2rem;
  font-family: 'DIN Pro Bold', sans-serif;
  text-transform: none;
  margin: 0;
}
h2.blue {
  color: $cornblue;
}
.left-panel {
  min-height: 100vh;
}
.left-panel, .download-panel {
  padding: 5vw;
}
.download-panel {
  background-color: $downloadPanelColor;
  min-height: 100vh;
}
.background-image {
  width: 100vw;
  height:100vh;
  position:fixed;
  top:0;
  left:0;
  z-index:0;
  background-size: cover;
}
content {
  z-index:1;
  position:relative;
}
.filler {
  flex-grow: 1;
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {

}
@media (max-height: 700px) and (min-width: 770px) {

}

/* iPad pro Landscape */
@media only screen
and (min-device-width: 1366px)
and (max-device-width: 1366px)
and (min-device-height: 1024px)
and (max-device-height: 1024px)
and (orientation: landscape)
and (-webkit-min-device-pixel-ratio: 2) {

    .left-panel {
      position:relative;
      z-index:1;
      flex: 0 0 58.3333333333% !important;
      max-width: 58.3333333333% !important;
    }
    .download-panel{
       position:relative;
        z-index:0;
        flex: 0 0 41.6666666667% !important;
        max-width: 41.6666666667% !important;
      .download {
        //position:absolute;
        //top:470px;
      }
    }
}

//tablets portrait-mode
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait), screen and (max-width: 300px) {
    h1.front {
      //position:absolute;
      //top:280px;
    }
    .h2.front {
      //position:absolute;
      //top:280px;
    }
    .left-panel {
      position:relative;
      z-index:1;
      flex: 0 0 58.3333333333% !important;
      max-width: 58.3333333333% !important;
    }
    .download-panel{
       position:relative;
        z-index:0;
        flex: 0 0 41.6666666667% !important;
        max-width: 41.6666666667% !important;
      .download {
        //position:absolute;
        //top:470px;
      }
    }
    .footer-links {
      position: absolute;
      bottom: 35px;
      width: 50%;
      * {
        float: none !important;
      }
    }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
    h1.front {
      font-size: 2rem;
      //position:absolute;
      //top:250px;
    }
    .h2.front {
      h2 {
        font-size: 1.8rem;
      }
      //position:absolute;
      //top:250px;
    }
    .left-panel {
      position:relative;
      z-index:1;
    }
    .download-panel{
       position:relative;
        z-index:0;
      .download {
        //position:absolute;
        //top:430px;
      }
    }
    .footer-links {
      position: absolute;
      bottom: 35px;
      width: 50%;
      * {
        float: none !important;
      }
    }
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
}

// ultra large
@media (min-width: 1699.98px) {
  .vstagelogo {
    max-width: 460px;
  }
  .logosvg {
    max-width: 400px;
  }
  h1.front {
    font-size: 3.5rem;
    margin-bottom:5vh;
  }
  h2.front {
    font-size: 2.5rem;
  }
  .download-panel, .left-panel {
    padding: 5vw !important;
  }
  .footer-links {
    font-size: 1.3rem;
    font-family: 'DIN Pro Bold', sans-serif;
  }
}
