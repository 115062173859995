@import "@/scss/_variables.scss";




















































































.posrotscalesetter {
  label {
    min-width: 100px;
  }
}
.rotlocscale-container {
  background-color: var(--vform-editor-ui-secondary-color);
}
