@import "@/scss/_variables.scss";


















































































































































































































































































































































































































































































































































.pseudo-button {
  margin-top: -5px;
}

.form-section {
  padding-top: 10px;
  padding-bottom: 10px;
}
