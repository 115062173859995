@import "@/scss/_variables.scss";



















.form-part {
  position:absolute;
  left: -100000px;
  opacity: 0;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  &.active {
    opacity: 1;
    position:static;
    left: auto;
  }
}
