@import "@/scss/_variables.scss";





























































































































































































































































































































































































.custom-select {
  max-width: 250px;
  border: 1px solid rgba(255, 255, 255, 0.3) !important;
}
