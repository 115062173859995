@import "@/scss/_variables.scss";


























































































.vform-header {
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  margin-bottom: 0.7em;
  h1, h2, h3 {
    margin-bottom: 0;
    padding-bottom: 0;
    text-transform: none;
    font-weight: 700;
    color: #000;
    &.with-bg {
      width: calc(100.5% + 42px);
      margin-left: -21px;
      padding: 1em 21px;
    }
  }
  h1 {
    font-size: 2em;
  }
  h2 {
    font-size: 1.7em;
  }
  h3 {
    font-size: 1.3em;
  }
  &.emphasized {
    background-color: var(--vform-editor-layout-accent-color);
    color: var(--vform-editor-emphasized-font-color);
    padding: 0.5rem 1rem;
  }
}
