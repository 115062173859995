@import "@/scss/_variables.scss";















































































































































































.leaf {
  font-size: 1rem;
  font-weight:bold;
  background-color: #665f5f;
  border: 1px solid lighten(#665f5f, 10%);
  border-left: 6px solid #baa53c;
}
