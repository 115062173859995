@import "@/scss/_variables.scss";












































































.action-button {
    clear:both;
    float:right;
    border-radius: 3px;
    .button a {
        background-color: $highlight;
        color: #fff;
    }
}
.button {
    display:inline-block;
    position: relative;
    text-transform: uppercase;
    font-family: 'DIN Pro Medium', sans-serif;
    cursor:pointer;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
    min-width:90px;
    .slim {
      font-family: 'DIN Light', sans-serif;
    }
    a, .a {
        display:block;
        text-align: left;
        background-color: $default-button-background;
        height: 100%;

        color: $default-button-font-color;
        -webkit-transition: all 300ms ease;
        transition: all 300ms ease;
        box-sizing: content-box;
      border-radius: 2px;
      font-size: 0.9rem;
        .content {
          position:relative;
          -webkit-transition: all 300ms ease;
          transition: all 300ms ease;
          padding: 5px 12px;
          height: 100%;
          display:block;
          width: 100%;
          font-size: 0.9rem;
          border-radius: 3px;
          .icon {
            margin-top: -1px;
          }
        }
    }
    &.highlight a, &.highlight .a {
         background-color: $button-highlight-background;
     }
    &.brighter a, &.brighter .a {
      background-color: lighten($default-button-background, 20%);
    }
  &.alert {
    padding:0;
    a, .a {
      background-color: $alert;
    }
  }
    &.deactivated {
        a, .a {
            background-color: $deactivated-button-background !important;
            cursor: not-allowed;
        }
    }
    &:hover {
        a, .a {
            color: $default-button-font-color;
            background-color: $button-hover-background;
        }
    }

    .icon {
        margin-left:10px;
        float:right;
        font-size:1.3em;
        //margin-top:-0.00em;
        -webkit-transition: all 300ms ease;
        transition: all 300ms ease;
      &.left {
        float:left;
        margin-right: 10px;
        margin-left:0;
      }
    }
  &.make-longer-animation:hover {
    a, .a {
      padding-right:25px;
    }
  }
  &.icon-rotate-animation:hover {
    .icon {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  &.icon-wiggle-animation:hover {
    .icon {
      -webkit-transform: rotate(-10deg);
      transform: rotate(-10deg);
    }
  }
  &.move-to-right-animation:hover {
    .icon {
      -webkit-transform: translateX(5px);
      transform: translateX(5px);
    }
  }
}
.info-panel {
    .button {
        &.deactivated {
            a, .a {
                background-color: darken($deactivated-button-background, 5%) !important;
            }
        }
    }
}
.icon-button {
  min-width: auto;
  .button {
    min-width: auto;
    height: $input-default-height;
    width: $input-default-height;
    position:relative;
    .a {
      min-height: 2rem;
    }
    .icon {
      position:absolute;
      top:50%;
      left:50%;
      -webkit-transform: translate(-50%,-50%);
      transform: translate(-50%,-50%);
      margin:0;
      padding:0;
    }
  }
}
