@import "@/scss/_variables.scss";





























































































































































.tree-collapse {
  position: absolute;
  top: -2px;
}
