/**
* Use this mixin to declare a set of CSS Custom Properties.
* The variables in $css_variables will be properly prefixed.
* The use of this mixin is encoraged to keep a good scalability.
*
* Usage:
*
* @include cssvars((
*  base-font-size: 65.5%,
*  font-family: #{"HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif},
*
*  primary-color: #33b5e5,
*  secondary-color: #ff500a,
* ));
*
* Will result in
*
* root {
  *    --prefix-var-name: value;
  *    --prefix-var-name: value;
  *    --prefix-var-name: value;
  * }
*
*/
@mixin ccdefaultvars($css_variables, $prefix: ccdefault) {
  :root {
    @each $name, $value in $css_variables {
      --#{$prefix}-#{$name}: #{$value};
    }
  }
}

// this transforms all variables like this for :root
// --vform-highlight
// --vform-on-dark-color
@include ccdefaultvars((
        h1-font-color: #000,
        h-addition-font-color: #aaa,
        normal-button-background: #555,
        normal-button-hover-background: lighten(#555, 10%),
        ticketing-container-header-color: #7262be,
        ticketing-container-header-color-hover: lighten(#7262be, 20%),
));