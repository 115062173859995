@import "@/scss/_variables.scss";

























































































































.form-text, .form-textarea {
    margin-bottom: 15px;
    &.error {
        border: 1px solid red;
    }
}
