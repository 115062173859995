@import "@/scss/_variables.scss";























































































































.vform-element .audio-preview {
  width: 240px;
}

.mr-audio-selected {
  margin-right: 130px;
}
