@import "@/scss/_variables.scss";












































































































































































































































.course-cat-overlay-edit {
  .cat-selector {
    color: #fff;
    border-radius: 2px;
    padding: 3px;
    cursor: pointer;
    background-color: var(--vform-editor-ui-secondary-color);
    -webkit-box-shadow: 0 8px 6px -5px black;
    box-shadow: 0 5px 6px -5px black;
    &:hover, &.active {
      background-color: #000;
      color: #fff;
    }
  }
}
