@import "@/scss/_variables.scss";

























































































































































































































































































































































.hotspot-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);

  z-index: 30;

  &.fade {
    background-color: var(--vform-editor-ui-secondary-color);
    opacity: 0.7;
    transition: all 600ms ease-in-out;
  }
}

.hotspot-dot-container {
  position: absolute;
  cursor: pointer;
  z-index: 2;

  &.learn.selected {
    box-shadow: 0 0 9px 6px white;
  }
}
.hotspots-active {
  z-index: 30;

  &.panel-visible {
    opacity: 0.8;
  }
}

.hotspot-icon {
  width: 20px;
  height: 20px;
  background-color: orangered;
  border-radius: 50%;
  box-shadow: 0 0 5px 3px white;
  z-index: 2;

  &.pulsate {
    animation-name: stretch;
    animation-duration: 1.0s;
    animation-timing-function: ease-out;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-play-state: running;

    &:hover {
      //animation: none;
    }
  }

  &.can-drag {
    background-size: 5px 5px;
    background-image: linear-gradient(to right, darkred 1px, transparent 1px),
    linear-gradient(to bottom, darkred 1px, transparent 1px);
    border-color: darkred;
  }

  &.learn {
    &.can-drag {
      background-size: 5px 5px;
      background-image: none;
    }

    background-color: #6a6ade;
    border-radius: 5%;
    width: fit-content;
    min-width: 35px;
    padding: 0 8px 0 8px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;

    &.case-2 {
      background-color: #1e7e34;
    }

    .no-selection {
      padding-bottom: 2px;
    }

    &.select-active {
      width: 42px;
    }
  }
}

@keyframes stretch {
  0% {
    transform: scale(0.9);
  }

  50% {
  }

  100% {
    transform: scale(1.3);
  }
}

.custom-tooltip-1 {
  white-space: nowrap;
  position: absolute;
  background-color: white;
  border-radius: 25px;
  z-index: 0;
  align-items: center;
  top: -3px;
  bottom: -3px;
  padding: 0 0.5em;
  display: flex;
  font-size: 1.05em;
  transition: all 300ms ease;
}

.tooltip-left {
  right: -4px;
  padding-right: calc(30px + 0.5em);
}

.tooltip-right {
  left: -4px;
  padding-left: calc(30px + 0.5em);
}
