@import "@/scss/_variables.scss";















































































































































































































































































































































































































































































































































































.linked-data-preview {
  min-height: 50vh;
  border: 1px dashed rgba(255,255,255,0.1);
}
.linked-data-filter {
  input {
    width: 100%;
  }
}

.toggler {
  cursor: pointer;

  i {
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
  }


  &.open {
    i {
      -webkit-transform: rotate(90deg);
      transform: rotate(90deg);
    }
  }

}

.collapsed {
  height: 0;
  overflow: hidden;
}

.linking-part > .pagination {
  ul {
    margin: 0 !important;
    float: right;
  }
}

#linked-items {
  table td.field-id-name, table th.field-id-name {
    width: auto;
  }
}

