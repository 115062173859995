@import "@/scss/_variables.scss";






































































































































































































































































































.colormat-color-preview {
  width: 15px;
  height: 15px;
  display: inline-block;
  .color-pickr {
    top: 50%;
    transform: translateY(-50%);
  }
}

.mat-color-row {
  width: 100%;
  background-color: $panel-title-background-color;
  position: relative;
}

.material-select {
  height: 40px;
  margin: auto 0;
  width: 100%;
  font-size: 0.8rem;
}

.label {
  font-family: 'Inter medium', sans-serif;
  margin-top: 15px;
}
.clr, .del {
  width: 50px;
  min-width: auto;
}
.remove-color-button {
  border-radius: 3px;
}

.mat-display {
  min-width: 200px;
}
.mini-text {
  font-size: 0.8rem;
}
