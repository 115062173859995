@import "@/scss/_variables.scss";



































































































































































.smaller {
  font-size: 0.9em;
}
.custom-select {
  border-radius: 4px;
}
.ticket-form-wrapper, .filter-wrapper {
  position: fixed;
  right: -800px;
  width: 800px;
  top: 30px;
  z-index:55;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  &.active {
    right: 0;
  }
  .leash {
    z-index:0;
    position: absolute;
    left: -55px;
    top: 200px;
    background-color: var(--vform-editor-ui-secondary-color);
    padding:8px 12px;
    transform: rotate(-90deg);
    transform-origin: top left;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    font-size: 1.1rem;
    box-shadow: -8px -8px 6px 0px rgba(0,0,0,0.75);
    -webkit-box-shadow: -8px -8px 6px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: -8px -8px 6px 0px rgba(0,0,0,0.75);
    .ticket {
      position: absolute;
      left: 12px;
      top: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
    }
    .text {
      margin-top: -3px;
    }
  }
  .ticket-form-overview {
    z-index:1;
    max-height: 90vh;
    overflow: scroll;
  }
}
.filter-wrapper {
  .leash {
    top: 390px;
  }
}
.hexenkessel {
  padding: 15px;
  padding-left: 60px;
  position: relative;
  background-color: #4d4286;
  border: 1px solid var(--ccdefault-ticketing-container-header-color);
  display: inline-block;
  border-radius: 4px;
  min-width: 60%;
  padding-right: 150px;
  .project-selector {
    position: absolute;
    top: 50%;
    right: 15px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    .project {
      &.active, &:hover {
        background-color: #3a3067;
        border: 1px solid #6552ba;
        box-shadow: 2px 1px 3px 0px rgb(0 0 0 / 75%) inset;
        -webkit-box-shadow: 2px 1px 3px 0px rgb(0 0 0 / 75%) inset;
        -moz-box-shadow: 2px 1px 3px 0px rgb(0 0 0 / 75%) inset;
        color: #ceced1;
      }
      &.passive {
        background-color: #4e4286;
        border: 1px solid #3a3067;
      }
    }
  }
  .icon {
    position: absolute;
    top: 50%;
    left: 15px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  h2 {
    margin-top: 5px;
    margin-bottom: 0;
  }
}
