@import "@/scss/_variables.scss";









































































































































































.webapp-highlight {
  background-color: $highlight !important;
}
