@import "@/scss/_variables.scss";




















































































































































































































































































.three {
  width: 100%;
  padding-top: 100%;
  position: relative;
  background: #2d2d33;

  canvas {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    font-size: 4.5em;
  }
}

.thumbnail.preview #threeContainer {
  position: absolute;
}
