@import "@/scss/_variables.scss";






































.the-ticket {
  background-color: #bbb;
  border-radius: 4px;
  position:relative;
}
.ticket-state {
  padding: 3px;
  border-radius: 4px;
  background-color: #be638f;
  &.new {
    background-color: #aaa;
  }
}
.priority {
  padding: 3px;
  border-radius: 4px;
  background-color: #aaa;
  display: inline-block;
}
.the-ticket:hover .priority {
 &.priority-1 {
    background-color: red;
  }
  &.priority-2 {
    background-color: #be638f;
  }
  &.priority-3 {
    background-color: #63be9e;
  }
}
.ticket-row {
   position:relative;
}
.ticket-type {
  padding: 8px;
  position:relative;
  font-size:1rem;
  border-radius: 4px;
  min-height:25px;
  color: #000;
  font-weight: bold;
}
.ticket-category {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #4e4f50;
  border-radius: 4px;
  padding: 2px;
}
.smaller {
  font-size: 0.9rem;
}
