@import "@/scss/_variables.scss";































































































.log-summary-field {
  background-color: var(--vform-editor-ui-secondary-color);
  color: #fff;
}
