@import "@/scss/_variables.scss";
































































































































































.slider {
    position:relative;
    padding-top:60px;
    padding-left:8px;
    padding-right:9px;
}
.noUi-target {
    background: $slider-handle-background-color;
    border: 1px solid $slider-handle-background-color;
    box-shadow:none;
}
.noUi-touch-area {
    background: $slider-handle-background-color;
    border: 2px solid darken($slider-handle-background-color, 20%);
    &:focus, &:active {
        -webkit-tap-highlight-color:transparent;
        outline:none;
        box-shadow:none;

    }
}
.noUi-horizontal .noUi-handle {
    height:20px;
    width: 25px;
}
.noUi-handle {
    border: 1px darken($slider-handle-background-color, 10%);
    box-shadow: none;
    border-radius:0;
    &:focus, &:active {
        outline: none
    }
}
.noUi-horizontal {
    height: 8px;
}
.noUi-handle:before, .noUi-handle:after {
    height: 7px;
    position:absolute;
    top:50%;
    left:50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
}
.noUi-horizontal .noUi-tooltip {
    bottom: 150%;
    background: $slider-tooltip-background-color;
    color: #fff;
    border: 1px solid $slider-border-color;
    border-radius:0;
}
.slider-message {
  position:absolute;
  top:15px;
  left:0;
}
