@import "@/scss/_variables.scss";




























































































































































































































































































































































































































$left-side: 160px;
.newStyle #form-export {
  h1.white {
    color: rgba(255,255,255,0.8);
  }
  h2 {
    font-family: 'Inter bold', sans-serif;
    font-weight: bold;
    text-transform: none;
    color: rgba(255,255,255,0.7);
    font-size: 1.2rem;
  }
}
.left-side {
  width: $left-side;
}
.generate-legend-box {
  border-top: 1px solid rgba(255,255,255,0.4);
  padding-top: 15px;
  margin-top: 15px;
  // margin-left: $left-side - 10px;
}

.export-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; 
  height: 100%;
  background: rgba(0, 0, 0, 0.5); // Semi-transparent overlay
  z-index: 1000; // Ensure it sits above interactive elements
  cursor: not-allowed; // Change cursor to indicate disabled state
}
