@import "@/scss/_variables.scss";










































































































.save-button-logger {
  color: #fff;
  background-color: #aaa;

  &:hover {
    background-color: darken(#aaa, 5%);
  }
}
.log-entries-component-logger {
  font-size: 0.9em;
}
.date-item {
  margin-bottom: -4px;
  font-size: 0.85em;
  opacity: 0.8;
}
