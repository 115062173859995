@import "@/scss/_variables.scss";













































































































































































































































































































































/*hr {
    margin: 0;
  }*/
.vform-alignment-button {
  background-color: var(--vform-editor-ui-secondary-color);
  border-radius: 1px;
  margin-left: 1px;
  width: 25px;
  height: 25px;
  position: relative;
  color: #fff;

  .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  &.active {
    background-color: var(--vform-editor-accent-color);
  }
}

.prop-tab-separator {
  height: 1px;
  background: var(--vform-editor-gizmos-primary-color);
  margin-bottom: -10px;
}

.label-prop-tab-separator {
  margin: 0;
  padding: 0 8px 0 8px;
  color: var(--vform-editor-gizmos-secondary-color);
  background: var(--vform-editor-gizmos-primary-color);
}
