@import "@/scss/_variables.scss";














































































































































































.vform-container {
  .info-helper {
    margin: auto 2px 4px 8px;
    cursor: pointer;

    .tooltip {
      left: -270px;
      width: 300px;
      min-width: 10px;
    }
  }
}
