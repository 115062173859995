@import "@/scss/_variables.scss";

















































































































































































































































































































































































































































































































.video-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
}

.bg-video {
  width: 100% !important;
  height: 100% !important;
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  outline: none;
  //animation-duration: 2.5s;
  //animation-direction: forwards;
  z-index: -1;
  &.active {
    opacity: 1;
    z-index: 2;
    //animation: 200ms fadeIn forwards;
  }

  // this is the previous video...
  &.inactive {
    //animation: 10ms fadeOut forwards;
    opacity: 0;
  }

  &.reallyinactive {
    opacity: 0;
    z-index: -1;
  }

  &:focus, &:active {
    outline: none;
    border: 0;
    box-shadow: none;
  }

  // for debugging
  &.split-mode {
    width: 50% !important;
    min-width: 50%;
    height: 50% !important;
    min-height: 50%;
    top:0;
    left:0;
    transform: none;
    outline: 1px solid red;
    &.inactive {
      opacity: 1 !important;
      bottom: 0;
      right: 0;
      top: auto;
      left: auto;
    }
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.curr-time {
  position: absolute;
  bottom: 65px;
  left: 15px;
  z-index: 9005;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 3px;
  border-radius: 3px;
}

.video-bg-loading, .form-error {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    z-index: 0;
  }
  to {
    opacity: 0;
    z-index: 0;
  }
}
