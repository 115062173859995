@import "@/scss/_variables.scss";




































select {
  height: $input-default-height;
}
