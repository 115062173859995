@import "@/scss/_variables.scss";















































































































































.loading-panel-download {
  position:absolute;
  z-index:55;
}
.not-absolute {
  .loading-panel-download {
    position:static;
  }
}
 #frame, #downloadForm {
    visibility:hidden;
   position:absolute;
   top:0;
    z-index: -1;
  }
