/*
--------------------
Color Preview (usually a little square)
--------------------
*/
.color-preview {
  width: 10px;
  height: 10px;
  &.large {
    width: 30px;
    height: 30px;
  }
  &.round {
    border-radius: 50%;
  }
  &.editable {
    &:hover, &:focus, &.hover {
      width: 35px;
      height:35px;
      border-radius: 50%;
    }
  }
}

.color-square {
  width:15px;
  height:15px;
}

/*
--------------------
Preview
--------------------
*/
.preview {
  .half-circle-spinner {
    position:absolute;
    top:50%;
    left:50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
  }
  .square-image {
    //background-color: $preview-background-color;
    .preview-icon {
      position:absolute;
      top:50%;
      left:50%;
      -webkit-transform: translate(-50%,-50%);
      transform: translate(-50%,-50%);
    }
  }
  &.mini {
    width: 40px;
    height: 40px;
  }
}
.preview-image {
  width: 100%;
  padding-top: 100%;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: $preview-background-color;

  i {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    font-size: 5vw;
  }
}
.square-image {
  width: 100%;
  padding-top: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position:relative;
}
.square-audio {
  width: 100%;
  padding-top: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  z-index: -1;
}
.lengthy-image {
  width: 100%;
  padding-top: 60%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position:relative;
}
.medium-thumbnail {
  @extend .table-thumbnail;
  width: 15vw;
  min-width: 45px;
}