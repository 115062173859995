/*
--------------------
Header
--------------------
*/
.logosvg {
  width: 100%;
  max-width: 210px;
  &.nav {
    position:absolute;
    top:50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  path, polygon {
    fill: $logocolor;
  }
}
.vstagelogo {
  width: 100%;
  max-width: 300px;
  margin-top: 25px;
  margin-bottom: 25px;
  path {
    fill: $logocolor;
  }
}

#main-logo {
  display:block;
  height:100%;
  width: 100%;
  position:relative;
  &.print { //for pdf-printing
    width: 300px;
    height: auto;
    .logosvg {
      width: 300px;
      max-width: none;
      path, polygon {
        fill: #000;
      }
    }
  }
}
.logo-section {
  position: relative;
  width: 100%;
  height: 100%;
  margin-left: -15px;
  background: rgb(10, 11, 11);
  background: linear-gradient(0deg, rgba(10, 11, 11, 1) 0%, rgba(25, 33, 41, 1) 100%);
  height: 120px; //fixed height because the spickel won't fit if the height changes
  a {
    z-index: 1;
    position: relative;
    display:block;
    padding-left:15px;
  }

  .spickel {
    width: 90px;
    background: rgb(10, 11, 11);
    background: linear-gradient(0deg, rgba(10, 11, 11, 1) 0%, rgba(25, 33, 41, 1) 100%);
    height: 100%;
    position: absolute;
    right: -84px;
    -webkit-transform: skew(-25deg, 0deg);
    transform: skew(-25deg, 0deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    left: auto;
    z-index: 0;
    top: 0;
  }
}
.subtitle-container {
  position: absolute;
}
.page-title {
  width: 100%;
  background: rgb(199, 89, 11);
  background: linear-gradient(-90deg, rgba(199, 89, 11, 1) 0%, rgba(65, 29, 3, 1) 100%);
  color: #fff;
  font-size: 1.2em;
  text-align: right;
  padding: 15px;
  margin-left: -15px;
  position: relative;
  text-transform: uppercase;
  //height: 55px;
  z-index:1;
  span {
    z-index:1;
  }

  i {
    margin-left: 10px;
  }
}
.page-title-container {
  .spickel {
    width: 80px;
    background-color: #c7590b;
    height: 100%;
    position: absolute;
    right: 3px; //-28px
    -webkit-transform: skew(-25deg, 0deg);
    transform: skew(-25deg, 0deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    left: auto;
    z-index: 0;
    top: 0;
  }
}