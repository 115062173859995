/*
--------------------
Info-Panel (usually on the left side in the content section of e.g. projects)
--------------------
*/
.info-panel {
  &.main-content-info-panel {
    max-width: 450px;
  }
  .form-text, .input-prepend-icon, .input-append-icon {
    border: 1px solid $standard-border-color;
    //color: #000;
  }
  .tag {
    background-color: var(--vform-editor-ui-quinary-color);
  }
}

.message-panel {
  background-color: $tab-item-background;
  width: 100%;
  &.bright {
    background-color: $tab-item-background;
  }
  &.dark {
    background-color: $tab-item-background-active;
  }
}