@import "@/scss/_variables.scss";




































$icon-width: 18px;
.icon1 {
  position:absolute;
  bottom: 0;
  right: 0;
  width: $icon-width;
  height: $icon-width;
  cursor: move;
}
.icon2 {
  position:absolute;
  bottom: 0;
  right: $icon-width + 3;
  width: $icon-width;
  height: $icon-width;
}
