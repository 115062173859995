@import "@/scss/_variables.scss";



























































































































































































































































.vform-image-preview {
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.vform-image-preview-preview {
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.square-image {
  .icon, .loading-spinner-widget, .loading-message {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .loading-message {
    font-size: 0.9rem;
    font-weight: bold;
    text-transform: uppercase;
    margin-top: 80px;
  }
}
