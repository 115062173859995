@import "@/scss/_variables.scss";






































































































































































































































































































































































































































































































































.dashboard-panel {
  background-color: var(--vform-editor-ui-secondary-color);
  padding: 15px;
}

.vform-label {
  color: #fff !important;
}

.date-item {
  margin-bottom: -4px;
  font-size: 0.8rem;
}

.break-word {
  word-wrap: break-word;
}

.small-font {
  font-size: 0.8rem;
}
