@import "@/scss/_variables.scss";




































































































































































#threeDragZone {
  position: relative;
  z-index: 1055;
  background-color: #e5e5f7;
  opacity: 0.8;
  background-size: 10px 10px;
  background-image: repeating-linear-gradient(-45deg, #34383e 0, #626974 1px, #21252b 0, #21252b 50%)
}
