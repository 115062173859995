@import "@/scss/_variables.scss";






















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.state-overlay {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(80, 188, 255, 0.4);
  border: 1px dashed #444;
  border-radius: 3px;
  padding: 5px;
  z-index: 180;
  pointer-events: none;
}
.panel-top-bar, .panel-bottom-bar {
  cursor: pointer;
  padding-top: 15px;
  padding-bottom: 15px;
  transition: all 300ms ease;
  position: absolute;
  width: 30px;
  top: 5px;
  z-index: 156;

  .hover-label {
    position: absolute;
    left: 100%;
    top: 0;
    background-color: rgba(0, 0, 0, 1);
    padding: 3px;
    border-radius: 3px;
    display: inline-block;
    min-width: 200px;
    opacity: 0;
    pointer-events: none;
    font-size: 0.9rem;
    z-index: 5;
  }

  .icon-container {
    opacity: 0.5;
    z-index: 4;
    border-radius: 50%;
    width: 80%;
    padding-top: 75%;
    margin: auto;
    background-color: var(--ccdefault-normal-button-hover-background);
    border: 1px solid rgba(255, 255, 255, 0.2);
    color: #fff;
    position: relative;
    margin-bottom: 3px;

    &:hover {
      opacity: 0.9;
      background-color: var(--ccdefault-normal-button-hover-background);

      .hover-label {
        animation: forwards 0.5s fadeIn;
        animation-delay: 0.8s;
      }
    }

    &.active {
      background-color: var(--vform-editor-ui-secondary-color);
    }

    &.bg-highlight {
      background-color: $highlight;
    }
  }

  .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 0.9rem !important;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.panel-bottom-bar {
  top: auto;
  bottom: 0;
  padding-bottom: 0;
}

.drop-zone {
  height: 23px;
  position: absolute;
  top: -16px;
  z-index: -1;
  width: 100%;
  left: 0;
}

.drop-zone-highlight {
  z-index: 50 !important;
}

.fit-drop-zone {
  min-height: 40px;
  z-index: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 100%;
  &.make-zone-larger {
    position:static;
    transform: none;
  }
}

.fixed-drop-zone {
  height: 10px;
  position: relative;
  z-index: -1;
  width: 100%;

  &.make-larger {
    height: 40px;
  }
}

.large-drop-zone {
  height: 100px !important;
  position: relative;
  top: 5px;
  -webkit-transform: translateY(-20px);
  transform: translateY(-20px);
  z-index: -1;
  width: 100%;
}

.drop-active {
  background-color: var(--vform-editor-accent-color);
  background-clip: content-box;
  opacity: 0.7;
  padding: 6px 0 8px 0;
}

.drop-not-allowed {
  cursor: not-allowed;
  background-color: red;
  background-clip: content-box;
  opacity: 0.3;
  padding: 6px 0 8px 0;
}

.element-display {
  border: solid 1px transparent;
  cursor: pointer;
}

.form {
  min-width: 250px;
  position: absolute;
  width: 100%;
  height: calc(100% - (var(--vform-default-vform-margin) * 2));
  z-index: 55;
  font-size: 0.95rem;
  margin: auto;
  color: var(--vform-on-bright-color);
  display: flex;
  flex-direction: column;

  &.error-mode {
    outline: 2px solid red;
  }

  &.active {
    right: var(--vform-default-vform-margin);
  }

  &.inactive {
    right: -100vw !important; // needs to be important because of locally set styles
    width: 0;
    overflow: hidden;
    opacity: 0;
    pointer-events: none;
  }

  &.no-bottom-bar {
    height: calc(
        90% - (var(--vform-default-vform-margin) * 2) -
        var(--vform-editor-carousel-height)
    );
  }

  &.has-bottom-bar {
    //margin-bottom: var(--vform-default-vform-margin);
    height: calc(
        100% - (var(--vform-default-vform-margin) * 2) -
        var(--vform-bottom-bar-height)
    );
  }
}

@media screen and (max-width: 800px) {
  :root {
    --vform-vform-margin: 23px;
  }
  .form {
    font-size: 0.9rem;
  }
}

.vform-preview {
  .form {
    padding-left: 0;
    position: static;
    padding-top: 0;
  }
}

.form-inner {
  position: relative;
  flex: 1;
  padding-left: 18px;
  padding-right: 18px;
  background-color: var(--vform-editor-ui-primary-color);
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;

  &.form-radius {
    border-radius: 3px;
  }

}

.master-panel {
  background-image: repeating-linear-gradient(45deg, rgba(47, 47, 67, 0.12) 0, rgba(47, 47, 67, 0.12) 1px, transparent 0, transparent 50%);
  background-size: 12px 12px;
  background-color: rgba(240, 255, 71, 0);
}

.form-spacer {
  padding-top: var(--vform-editor-header-bar-height);
}

.form.empty {
  .form-inner {
    background-color: transparent;
  }
}

.form-inner-inner {
  min-height: 80%;
  width: 100%;
}

.button-container {
  width: 100%;
  border-top: 1px solid #ddd;
  display: flex;
  // flex-direction: column;
  position:relative;
  flex: 0;
  background-color: #fff;
  z-index: 100;
  padding-left: 18px;
  padding-right: 18px;
  margin: 0;
  &.bar-border-radius {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }
}

.home-button {
  color: var(--vform-editor-layout-accent-color);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;

  &:hover {
    background-color: var(--vform-neutral-color);
  }
}

@keyframes slideIn {
  from {
    left: 0;
    width: 0;
  }
  to {
    width: 2vw;
    left: -3vw;
  }
}

.slide-header {
  width: 100%;
  z-index: 15;
  margin-top: 15px;
  color: var(--vform-on-bright-color);
}

.panel-form-wrapper {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 20;
  cursor: auto;
  display: flex;

  &.inactive {
    display: none;
  }
}

.active-col-step-properties {
  .panel-form-wrapper {
    width: 100%;
    height: 100%;
  }
}

.active-col-properties, .active-col-blocks, .active-col-hotspots, .active-col-learning-dots {
  .panel-form-wrapper {
    pointer-events: none;
  }
}

.ns-resize-handle {
  background: transparent;
  cursor: ns-resize;
  width: calc(100% - 25px);
  height: 25px;
  top: -25px;
  left: 0;
  position: absolute;
  z-index: 100;
  opacity: 0.4
}

.ew-resize-handle {
  background: transparent;
  cursor: ew-resize;
  width: 20px;
  height: calc(100% - 25px);
  top: 0;
  position: absolute;
  z-index: 100;
  right: 0;
}

.nwse-resize-handle {
  background: transparent;
  cursor: nwse-resize;
  width: 25px;
  height: 25px;
  bottom: 0;
  position: absolute;
  z-index: 101;
  right: 0;
}

.panel-container {
  pointer-events: all;
}

.step-panel-in-background {
  opacity: 0.8;
}

.panel-bottom-bar {
  .vform-slide-control-element.BUTTON_ITEM {
    margin: auto;
  }
}

.edit-indicator {
  position: absolute;
  top: -6px;
  left: -6px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  z-index: 150;
  box-shadow: 1px 1px 7px 4px rgb(0 0 0 / 13%);
  color: #fff;

  .number {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

/***
---------------
Media Queries
--------------
*/
.panel-form-wrapper {
  font-size: 16px;
  // background-color: white;
}

// X-Small devices (portrait phones, less than 576px)
@media (min-width: 576px) {
  .panel-form-wrapper {
    font-size: 12px;
    // background-color: red;
    .form {
      min-width: 200px;
    }
  }

  .vform-tile-container {
    padding: 0.5rem !important;
  }
}

// Small devices (landscape phones, less than 768px)
@media (min-width: 768px) {
  .panel-form-wrapper {
    font-size: 13px;
   // background-color: yellow;
  }

  .vform-tile-container {
    padding: 0.7rem !important;
  }
}

// Large devices (desktops, less than 1200px)
@media (min-width: 992px) {
  .panel-form-wrapper {
    font-size: 14px;
   // background-color: blue;
  }

  .vform-tile-container {
    padding: 1rem !important;
  }
}

// Medium devices (tablets, less than 992px)
@media (min-width: 1200px) {
  .panel-form-wrapper {
    font-size: 14px;
   // background-color: brown;
  }

  .vform-tile-container {
    padding: 0.8rem !important;
  }
}

// X-Large devices (large desktops, less than 1400px)
@media (min-width: 1400px) {
  .panel-form-wrapper {
    font-size: 16px;
   // background-color: violet; // done
  }

  .vform-tile-container {
    padding: 1.1rem !important;
  }
}

// XXX-Large devices (larger desktops, 1400px and up)
@media (min-width: 2400px) {
  .panel-form-wrapper {
    font-size: 18px;
   // background-color: black;
  }

  .vform-tile-container {
    padding: 1.1rem !important;
  }
}

