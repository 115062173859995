@import "@/scss/_variables.scss";







































































































































































































































































































.fixed-pos {
  position: fixed;
  top: 120px;
  width: 400px;
  background-color: $highlight;
}
