@import "@/scss/_variables.scss";


























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































$side-icons-height: 30px;
body {
  font-family: "Inter", sans-serif;
}

.vform-screen-size-selector {
  z-index: 555;
}

.undocker, .hamburger, .gear {
  position: absolute;
  left: -30px;
  top: 15px;
  padding: 5px;
  z-index: 105;
  width: $side-icons-height;
  height: $side-icons-height;
  background-color: var(--vform-editor-ui-secondary-color);
  cursor: pointer;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;

  .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  &:hover, &.active {
    background-color: var(--vform-editor-ui-tertiary-color);
    color: #fff;
  }
}

.gear {
  padding: 0;
  top: 48px;
  position: absolute;
  z-index: 106;
}

.hamburger {
  padding: 0;
  top: 64px;
  position: absolute;
  z-index: 555;

  .hamburger-inner {
    width: $side-icons-height - 10px;
    margin-top: 0;
    height: $side-icons-height;
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    position: absolute;

    div {
      width: 100%;
      height: 2px;
      background-color: #fff;
      position: absolute;
      top: 8px;
      -webkit-transition: all 300ms ease;
      transition: all 300ms ease;
    }

    div:nth-child(1) {
      top: 14px;
    }

    div:nth-child(2) {
      top: 20px;
    }

    &.active {
      div:nth-child(1) {
        top: 50%;
        transform: rotate(45deg);
      }

      div:nth-child(2) {
        display: none;
      }

      div:nth-child(3) {
        top: 50%;
        transform: rotate(-45deg);
      }
    }
  }
}

.vform-inline-menu {
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  width: 0;
  height: 0;
  overflow: hidden;
  left: 0;
  top: 15px;
  transform-origin: top right;
  z-index: 0;
  position: absolute;
  padding: 0;
  border-radius: 4px;

  &.active {
    width: 400px;
    height: 80vh;
    background-color: var(--vform-editor-layout-secondary-color);
    left: -400px;
    z-index: 105;
    padding: 15px;
    padding-top: 20px;
    padding-right: 35px;
    overflow: auto;
  }
}

.form-loading-screen {
  width: 100%;
  height: 100vh;
  background-color: var(--vform-editor-ui-secondary-color);
  top: 0;
  left: 0;
  z-index: 556;
  color: #fff;
  position: relative;

  .spinner-simple {
    display: none;
  }

  &.full-width {
    width: 100vw;
    position: fixed;
  }

  .loading-spinner-widget {
    margin-top: -3rem;
  }

  .loading-message {
    font-size: 0.8rem;
    font-weight: bold;
    text-transform: uppercase;
    margin-top: 50px;
  }

  .icon, .loading-spinner-widget, .form-error, .loading-message {
    color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}

.bottom-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  -webkit-box-shadow: 2px 2px 10px 2px #666;
  box-shadow: 2px 2px 10px 2px #666;
  background-color: var(--vform-bottom-bar-background-color);
  color: var(--vform-bottom-bar-text-color);
}

.drop-active {
  background-color: var(--vform-editor-accent-color);
  background-clip: content-box;
  opacity: 0.7;
  padding: 12px 0 14px 0;
}

.drop-zone {
  height: 35px;
  position: absolute;
  top: -20px;
  z-index: -1;
  width: 100%;
}

.large-drop-zone {
  height: 400px !important;
  position: relative;
  top: -20px;
  z-index: -1;
  width: 100%;
}

.drop-area-outer {
  border: dotted 1px gray;
  border-radius: 4px;
}

.drop-zone-highlight {
  z-index: 50;
}

.vform-element h2 {
  display: inline-block;
  color: var(--vform-editor-ui-tertiary-color);
  padding: 5px 0 5px 0;
  border-bottom: solid 1px var(--vform-editor-ui-secondary-color);
}

.icon-disabled {
  color: #5b5b5b;
  opacity: 0.2;
  cursor: default;
}

.icon-disabled .icon:hover {
  color: #5b5b5b;
  cursor: default;
}

.v-form-label {
  min-height: 150px;
}

.scrollspy-target {
  border: 2px solid red;
}

// TODO ???? why is this ?
.block-container {
  .form-block input,
  textarea,
  span,
  label {
    cursor: move;
    font-size: 10px;
  }
}

/*new style */
.dark-ui {
  background-color: var(--vform-editor-panel-active-background-color);
}

.step-properties {
  margin-left: auto;
  margin-right: 10px;
}

.slider-label {
  font-size: 0.733rem;
}

.drag-grip-global-settings {
  justify-content: flex-end;

  &:hover {
    color: var(--vform-editor-accent-color);
  }
}

