@import "@/scss/_variables.scss";
















































































































































































































































































































.hotspot {
  &.selected {
  }
}

.settings-button.action-button {
  float: unset;
}

.validation-error{
  font-size: 0.875em;
  color: orange;
  font-weight: 400;
  text-transform: none !important;
}
