@import "@/scss/_variables.scss";


















































































































































































































































































































































































































































































































































































































.meta-field {
  background-color: $meta-field-background-color;
}

pre {
  color: #fff;
}

.meta-field-label {
  font-size: 1em;
  margin-right: 10px;
  width: 20%;
  min-width: 100px;

  &.editing {
    background-color: $input-background-color;
  }
}

.meta-field-value {
  background-color: darken($meta-field-background-color, 10%);
  border: 1px solid darken($meta-field-background-color, 15%);
}

td, th {
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

td.default-value {
  width: 100px;
}

table tr {
  border: 1px solid darken($meta-field-background-color, 10%);
}

.table-edit {
  position: relative;
  height: 100%;
  cursor: pointer;
  width: 40px;
  min-width: 40px;
  border-right: 1px solid $input-background-color;

  .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
    opacity: 0.8;
  }

  &:hover {
    .icon {
      opacity: 1;
    }
  }
}
