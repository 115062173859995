@import "@/scss/_variables.scss";






































































































































































































































































































































































































































































































































































































.pivot-selector {
  z-index: 250;
  position:relative;
}
