@import "@/scss/_variables.scss";















































































































































































































































































.block-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 5px;
}
.form-block .checkmark {
  background: var(--vform-editor-ui-tertiary-color);
  border: solid 1px var(--vform-editor-ui-secondary-color);
}
/* When the checkbox is checked, add a blue background */
.form-block .container input:checked ~ .checkmark {
  background-color: gray;
}
.form-block .radio {
  background: var(--vform-editor-ui-tertiary-color);
  //border: solid 1px var(--vform-editor-ui-quaternary-color);
}
/* When the checkbox is checked, add a blue background */
.form-block .container input:checked ~ .radio {
  background-color: gray;
}
.form-block {
  background-color: var(--vform-editor-ui-secondary-color);
  color: var(--vform-editor-ui-primary-color);
  //border: solid 4px var(--vform-editor-panel-active-background-color;
  border-radius: 8px;
  min-height: 50px;
  height: 100%;
  padding: 12px;
  -webkit-box-shadow: 0 8px 6px -5px black;
  -moz-box-shadow: 0 8px 6px -5px black;
  box-shadow: 0 8px 6px -5px black;
  //box-sizing: border-box;
  //margin: 3px;

  &.disabled {
    opacity: 0.2;
  }
}
.form-block-image {
  display: flex;
  justify-content: center;
  line-height: 30px;
  color: var(--vform-editor-ui-tertiary-color);
  button {
    color: var(--vform-editor-ui-quaternary-color);
    background-color: var(--vform-editor-ui-tertiary-color);
    border-radius: 4px;
    border: solid 1px var(--vform-editor-ui-secondary-color);
    font-size: 0.95rem;
    min-width: 100px;
    line-height: 25px;
  }
}
.form-block a {
  text-decoration: underline;
  font-size: 10px;
}
.form-block input,
textarea {
  width: 100%;
  cursor: pointer;
}
.form-block {
  &:not(.disabled):hover {
    // border: solid 2px #da5e06;
    cursor: move;
    background-color: var(--vform-editor-ui-quinary-color);
    opacity: 0.99;
    //padding: 14px;
  }
  &.disabled {
    cursor: not-allowed;

    > textarea, span, label{
      cursor: not-allowed;
    }
  }
}
.form-block h2 {
  //font-weight: 700;
  //font-size: 13px;
}
.form-block {
  h3,
  label {
    color: var(--vform-editor-ui-tertiary-color);
    font-weight: 400;
    //font-size: 0.8rem;
  }
}
.form-block input,
textarea {
  border-width: 1px !important;
  color: var(--vform-editor-ui-quaternary-color) !important;
  background-color: var(--vform-editor-ui-tertiary-color) !important;
}
.form-block label.container {
  font-size: 10px;
}
.draggable {
  z-index: 10000;
}
.info-panel {
  background-color: var(--vform-editor-panel-active-background-color);
}
.separatorBlock {
    background-color: var(--vform-editor-ui-tertiary-color);
    height: 3px;
}

.verticalSpaceBlock {
    border-top: solid 1px var(--vform-editor-ui-tertiary-color);
    border-bottom: solid 1px var(--vform-editor-ui-tertiary-color);
    height: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 2px;
    margin-left: auto;
    margin-right: auto;
    width: 40%;
}

.block-container {
    .form-block input,
    textarea,
    span,
    label {
        cursor: inherit;
        font-size: 10px;
    }
}
