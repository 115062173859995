@import "@/scss/_variables.scss";














































































































































































































































































.model-edit-view {
  h2 {
    font-weight: bold;
  }
}
