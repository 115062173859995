@import "@/scss/_variables.scss";




















.vform-sign-out {
  font-size: 1rem;
  align-self: start;
  border-radius: 3px;
  min-width: 120px;
  border: 1px solid var(--vform-editor-layout-primary-color);
  color: var(--vform-editor-layout-primary-color);
  text-transform: uppercase;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  padding-left: 2rem;
  position: relative;
  .icon {
    position:absolute;
    left: 5px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  &:hover {
    background-color: var(--vform-editor-layout-primary-color);
    color: var(--vform-editor-layout-quaternary-color);
  }
}
