@import "@/scss/_variables.scss";













































.popup-container {
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    width: 0;
    height: 0;
    overflow: hidden;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 10000;

    &.open {
        width: 100vw;
        height: 100vh;
    }
}

.popup {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background-color: var(--vform-editor-ui-secondary-color);
    max-height: 100vh;
    overflow: auto;
}

.popup-close {
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 1.4em;
    color: #fff;
    cursor: pointer;
}
