@import "@/scss/_variables.scss";
























































































































































































































































.vform-form-text:disabled {
  background-color: transparent;
}

.with-icon {
  flex-grow: 1 !important;
}
.settings-button {
  padding: 8px;
}
