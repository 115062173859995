@import "@/scss/_variables.scss";














































.filterSection {
    border-bottom: 1px solid $standard-border-color;
    padding-bottom:20px;
    padding-top:7px;
    &:last-child {
        border-bottom: 0;
    }
    &.collapsible {
        border-bottom: 0 transparent;
        padding-bottom: 0;
        .header {
            background-color: $filter-collapsible-background-color;
            padding: 18px;
            padding-top: 3px;
            padding-bottom: 3px;
        }
        .content {
            background-color: $filter-collapsible-content-background;
        }
    }
}
label {
    width: 100%;
}
