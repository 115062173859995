@import "@/scss/_variables.scss";























































































































































































































































































.tag-input {
  position: relative;
  .form-text {
    margin-bottom: 0;
  }
}

.color-preview {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: pointer;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  border: 1px solid transparent;
}

.color-preview-container {
  min-width: 40px;
  height: 100%;
  position: relative;
  display: inline-block;
}
