@import "@/scss/_variables.scss";























































































































































































.vform-heading-cat-button {
  background-color: var(--vform-editor-ui-secondary-color);
  border-radius: 1px;
  margin-left: 1px;
  width: 25px;
  height: 25px;
  position: relative;
  color: #fff;
  transition: all 300ms ease;


  .size {
    vertical-align: baseline;
    font-size: 0.7rem;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    margin-top: 1px;
  }

  &.active, &:hover {
    background-color: var(--vform-editor-accent-color);
  }
}
