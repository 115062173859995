@import "@/scss/_variables.scss";
































































.view-switcher {
    display:inline-flex;
  height: $input-default-height;
}
.view-option {
    background-color: #2a2a2a;
    max-width:35px;
    padding:4px 7px;
    text-align: center;
    border-right: 1px solid #000;
    cursor:pointer;
    font-size: 0.9rem;
    border-radius: 2px;
    transition: all 300ms ease;
    &:last-child {
        border-right: 0px;
    }
    &:hover, &.active {
        background-color: $highlight;
    }
}
