/*
--------------------
Tables
--------------------
*/
table {
  width: 100%;
}
.table-fixed {
  table-layout: fixed;
}
.table-thumbnail {
  width: 100%;
  .square-image {
    width: 100%;
    padding-top: 50%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
}

//table-td-overlay
td, th {
  position:relative;
  .table-td-overlay {
    opacity: 0;
    width: 100%;
    height:100%;
    position:absolute;
    top:0;
    left:0;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
    background-color: $highlight;
  }
  &:hover {
    .table-td-overlay {
      opacity: 1;
    }
  }
}
/*
table slideouts:
a widget sitting in a th/td which expands on click
wrap div with <transition name="toggle-table-field-slideout" mode="out-in"></transition>
*/
.table-td-slideout {
  position:absolute;
  min-width: 200px;
  width: 15vw;
  min-height: 200px;
  left:0;
  background-color: $table-header-darker-color;
  padding:8px;
}
//transition vue toggling visibility toggler
.toggle-table-field-slideout-enter-active, .toggle-table-field-slideout-leave-active {
  transition: all .3s ease-out;
  width: 300px;
  max-height: 100vh;
  overflow:visible;
}
.toggle-table-field-slideout-enter, .toggle-table-field-slideout-leave-to {
  width: 0;
  overflow:hidden;
  max-height:0;
}