@mixin background-opacity($color, $opacity: 0.3) {
  background: $color; /* The Fallback */
  background: rgba($color, $opacity);
}


/**
* Use this mixin to declare a set of CSS Custom Properties.
* The variables in $css_variables will be properly prefixed.
* The use of this mixin is encoraged to keep a good scalability.
*
* Usage:
*
* @include cssvars((
*  base-font-size: 65.5%,
*  font-family: #{"HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif},
*
*  primary-color: #33b5e5,
*  secondary-color: #ff500a,
* ));
*
* Will result in
*
* root {
  *    --prefix-var-name: value;
  *    --prefix-var-name: value;
  *    --prefix-var-name: value;
  * }
*
*/
@mixin vformvars($css_variables, $prefix: vform) {
  :root {
    @each $name, $value in $css_variables {
      --#{$prefix}-#{$name}: #{$value};
    }
  }
}

// this transforms all variables like this:
// --vform-highlight
// --vform-on-dark-color
@include vformvars((
        highlight: orange,
        highlight-color:#172445,
        highlight-hover-color: lighten(#172445, 10%),
        highlight-checkbox-active-color: #000,
        on-dark-color: #fff,
        vform-header-background-color: #e3e3e3,
        on-bright-color: #000,
        neutral-color: #ddd,
        darker-neutral-color: #c0c1c2,
        default-vform-margin: 55px,
        mobile-vform-margin: 15px,
        /**
        Background color of the form
        */
        display-background-color: rgba(255,255,255,0.8),
        input-background-color: #fff,
        button-color: #fff,
        bottom-bar-background-color: rgba(0,0,0,0.8),
        bottom-bar-text-color: #fff,
        bottom-bar-inactive-color: #999,
        bottom-bar-hover-color: #bbb,
        bottom-bar-hover-background-color: rgba(0,0,0,0.8),
        bottom-bar-height: 60px,
        error-color: red,
        viewer-editor-icon-color: #fff,
));


/**
* Use this mixin to declare a set of CSS Custom Properties.
* The variables in $css_variables will be properly prefixed.
* The use of this mixin is encoraged to keep a good scalability.
*
* Usage:
*
* @include cssvars((
*  base-font-size: 65.5%,
*  font-family: #{"HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif},
*
*  primary-color: #33b5e5,
*  secondary-color: #ff500a,
* ));
*
* Will result in
*
* root {
  *    --prefix-var-name: value;
  *    --prefix-var-name: value;
  *    --prefix-var-name: value;
  * }
*
*/
@mixin vformEditorVars($css_variables, $prefix: vform-editor) {
  :root {
    @each $name, $value in $css_variables {
      --#{$prefix}-#{$name}: #{$value};
    }
  }
}

// this transforms all variables like this:
// --vform-editor-highlight
// --vform-editor-on-dark-color
@include vformEditorVars((
        primary-font: 'Inter',
        panel-active-background-color: #1B1A20,
        panel-neutral-background-color: #2D333D,
        carousel-height: 128px,
        vform-editor-height: 100vh,
        button-container-height: 65px,
        header-bar-height: 45px,
        logo-color: #8C9EA2,
        accent-color: #FF7E35,
        accent-color-hover: #c7590b,
        layout-primary-color: #101014,
        layout-secondary-color: #1B1A20,
        layout-tertiary-color: #2D333D,
        layout-quaternary-color: #fff,
        layout-accent-color: #172445,
        ui-primary-color: #F4F4F4,
        ui-secondary-color: #43414B,
        ui-tertiary-color: #B0B0B0,
        ui-quaternary-color: #0F0E11,
        ui-quinary-color: #24232A,
        ui-bottom-bar-color: rgba(#1A1B1F, 0.7),
        ui-bottom-bar-button-color: #373A47,
        gizmos-primary-color: #4B30D8,
        gizmos-secondary-color: #EEEBFF,
        gizmos-primary-color-darker: darken(#4B30D8, 15%),
        gizmos-primary-color-brighter: rgba(#4B30D8, 0.3),
        emphasized-font-color: #FFFFFF,
));
