@import "@/scss/_variables.scss";












































































.remove-item {
    border-radius:50%;
    width:20px;
    height:20px;
    font-size:0.9em;
    position:relative;
    margin-right:15px;
    margin-top:2px;
    cursor:pointer;
    .icon {
       position:absolute;
        top:50%;
        left:50%;
        -webkit-transform: translate(-50%,-50%);
        transform: translate(-50%,-50%);
    }
    &:hover {
        color: $highlight;
    }
}
