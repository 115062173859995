@import "@/scss/_variables.scss";













































































.cached {
  color: #19e719;
}
.not-cached {
  color: grey;
}
button {
  background-color: var(--vform-editor-ui-secondary-color);
  color: #fff;
  border: 1px solid rgba(255,255,255,0.5);
  border-radius: 2px;
  margin-right: 5px;
  transition: all 300ms ease;
  &:hover {
    background-color: #333;
  }
}
button:disabled {
  background-color: grey;
  color: #ddd;
}
