@import "@/scss/_variables.scss";





























































































































































































































































































































































































































































































































































































































































































































.font-size {
  padding-left: 7px;
  padding-right: 7px;
  margin: 1px;
  cursor: pointer;
  line-height: 30px;

  &.active {
    font-weight: bold;
    color: $highlight;
  }
}

.app-link {
  &:hover {
    color: $highlight;
  }
}

.vform-font-size-button {
  display: flex;
  align-items: baseline;
  justify-content: center;
  background: transparent;
}

.font-size-row {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.vform-language-selector {
  z-index: 555;
}
