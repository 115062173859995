@import "@/scss/_variables.scss";






































































































































































.app-panel-field {
  width: 220px;
}

.tag {
  background-color: $tag-background-color;
  display: inline-block;
  padding: 3px 35px 3px 8px;
  margin-right: 8px;
  position: relative;
  cursor: pointer;
  word-break: break-all;

  .icon-right {
    position: absolute;
    right: 10px;
    top: 15px;
    opacity: 0.8;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }

  .tag-details {
    background-color: darken($tag-background-color, 10%);
    padding: 3px;
    width: 100%;
    margin-top: 6px;
  }
}

.organization-row {
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
  padding: 1px;
}

.long-tag {
  margin-right: 0;
}

.orgname {
  width: 120px;

}
