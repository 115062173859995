@import "@/scss/_variables.scss";










































































































































































































































.item-chooser {
    padding:8px;
    background-color: var(--vform-editor-ui-quaternary-color);
    cursor:pointer;
    height: 100%;
    &:hover, &.selected {
        background: $highlight;
    }
}
.content-list-content-filter {
  //position: absolute;
  //top: 15px;
}
.content-list-widget {
  //padding-top: 15px;
}
.list-container {
  max-height: 500px;
  overflow: auto;
}
