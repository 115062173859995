@import "@/scss/_variables.scss";





























































































































.active-value {
  position:absolute;
  left: 5px;
  color: #fff;
}
.up-and-down {
  position: absolute;
  right: 5px;
  margin-top: 2px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.opacity-0 {
  opacity: 0;
  z-index: 0;
}
.invisible, .hidden {
  visibility: hidden;
  opacity: 0;
}

.dropdown-widget {
  font-size: 0.9rem;
  position: relative;
  color: #fff;
  align-self: start;
  background-color: var(--vform-editor-ui-secondary-color);
  border-radius: 2px;
  min-width: 50px;
  padding-top: 2px;
  padding-bottom: 2px;
  -webkit-box-shadow: 0 8px 6px -5px black;
  box-shadow: 0 5px 6px -5px black;
  &.no-shadow {
    box-shadow: none;
  }
  &.dark {
    background-color: var(--vform-editor-ui-quaternary-color);
  }
  &.open {
    z-index: 5000;
  }

  .check {
    visibility: hidden;

    &.visible {
      visibility: visible;
    }
  }

  .selected {
    cursor: pointer;
    color: #fff;
  }

  .options {
    position: absolute;
    background-color: var(--vform-editor-layout-secondary-color);
    border: 1px solid rgba(255, 255, 255, 0.2);
    // color: var(--vform-editor-layout-quaternary-color);
    color: #fff;
    -webkit-box-shadow: 0 8px 6px -5px black;
    box-shadow: 0 5px 6px -5px black;
    padding: 2px;
    max-height: 200px;
    overflow-y: scroll;
    overflow-x: hidden;
    cursor: pointer;
    z-index: 5;
    left: 0;
    min-width: 100%;
    border-radius: 3px;

    .selected {
      background-color: var(--vform-editor-layout-tertiary-color);
    }

    &.closed {
      z-index: 0;
      height: 0;
      padding: 0;
      overflow: hidden;
      opacity: 0;
    }

    div {
      padding: 2px;

      &:hover {
        background-color: var(--vform-editor-ui-secondary-color);
      }
    }

    &.top {
      bottom: 0;
    }

    &.bottom {
      top: 0;
    }
  }
}
