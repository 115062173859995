@import "@/scss/_variables.scss";













































































































































































































































.level-1 {
  padding-left: 5px;
}

.level-2 {
  padding-left: 1rem;
}

.level-3 {
  padding-left: 1.8rem;
}

.level-4 {
  padding-left: 2.4rem;
}

.level-5 {
  padding-left: 3rem;
}

.level-6 {
  padding-left: 3.5rem;
}

.node {
  width: 100%;
  margin-bottom: 1px;
  background-color: rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  display: flex;
  position: relative;
  cursor: pointer;

  .name {
    word-wrap: break-word;
    position: relative;
    max-width: 80%;
  }

  .icon-box {
    position: absolute;
    right: 5px;
  }

  .icon-box-2 {
    position: absolute;
    right: 20px;
  }

  &.highlighted, &:hover {
    background-color: $highlight;
  }

  .highlighted-2 {
    position: absolute;
    right: -8px;
    width: 8px;
    height: 100%;
    background-color: #2da2ad;
  }

  &.selected, &:hover {
    background-color: var(--vform-editor-ui-quaterniary-color);
  }
}

