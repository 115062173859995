@import "@/scss/_variables.scss";









































































































































.preview-label {
    width: 100%;
    padding: 15px;
    background-color: $background;
}
.item-chooser {
    padding:15px;
    background: $panel-background-color;
    cursor:pointer;
    &:hover, &.selected {
        background: $highlight;
    }
}
