@import "@/scss/_variables.scss";













































































































































































































































































.tree-view-container {
  position: relative;
  padding-top: 70px;
  background-color: var(--vform-editor-ui-secondary-color);

}

.tree-view {
  height: 80vh;
  overflow-Y: scroll;
  padding-right: 15px;
  padding-left: 5px;
  background-color: rgba(0, 0, 0, 0.2);
}

.stuck-on-top {
  position: absolute;
  top: 15px;
  left: 15px;
}
.vform-label {
  margin-bottom: 0;
}
