@import "@/scss/_variables.scss";




















































































.invisible {
  visibility: hidden;
  height: 0;
  overflow: hidden;
}

.form-switch {
  border-radius: 3px;
}
