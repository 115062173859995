@import "@/scss/_variables.scss";















































































































































































.selectedTable {
  tr {
    &.even, &.odd {
      background-color: $highlight;
    }
    .icon {
      &:hover svg {
        color: rgba(255,255,255,0.3);
      }
    }
  }
}
