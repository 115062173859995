@import "@/scss/_variables.scss";













































































































































































































.vform-panel {
    background-color: var(--vform-editor-layout-tertiary-color);
    border-radius: 5px;
    position: relative;
    color: var(--vform-editor-ui-tertiary-color);
    label:nth-child(1) {
        color: var(--vform-editor-ui-tertiary-color);
    }
}
.color-remover {
    border-radius: 3px;
}
.panel-remover {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
}
