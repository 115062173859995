@import "@/scss/_variables.scss";




























































































































































































































.message {
    display: flex;
}
