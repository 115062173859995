@import "@/scss/_variables.scss";
















































































































































































































































































.smaller {
  font-size: 0.6rem;
}
