@import "@/scss/_variables.scss";






























































































































































































































































































.connection-delete {
  cursor: pointer;
}
