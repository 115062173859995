@import "@/scss/_variables.scss";






















































































































































.drop-zone-step {
  line-height: 25px;
  margin-left: 5px;
  margin-right: 5px;
}

.drop-zone-step.highlight {
  line-height: 23px;
  border: dotted 1px var(--vform-editor-accent-color);
  border-radius: 3px;
}

.drop-zone-step.drop-active {
  background-color: var(--vform-editor-accent-color);
  background-clip: content-box;
  opacity: 0.7;
}
