@import "@/scss/_variables.scss";




























































































































































































































































































































































































































































































.taskfield {
  display:inline-block;
  min-width: 150px;
}
.force-resume {
  position: absolute;
  bottom:3px;
  right:3px;
  width: 20px;
  height: 20px;
  transition: all 300ms ease;
  &:hover {
    background-color: $highlight;
  }
}
//transition
.toggle-enter-active, .toggle-leave-active {
    transition: all .3s ease-out;
    height: 80px;
    overflow:visible;
}
.toggle-enter, .toggle-leave-to {
    height: 0;
    overflow:hidden;
}

.data-row-delete {
    cursor:pointer;
    opacity:0.8;
    &:hover {
        opacity:1;
    }
}
.table-filler {
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
    position:relative;
    .icon {
        -webkit-transition: all 300ms ease;
        transition: all 300ms ease;
        opacity: 0;
        position:absolute;
        right: 6px;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        font-size: 0.8em;
    }
    &:hover {
        .icon {
            opacity: 1;
        }
    }
}
