@import "@/scss/_variables.scss";





































.checkbox-container {
    .checkbox {
        float:left;
        width: 20px;
        height: 23px;
        margin-right: 3px;
    }
}
