@import "@/scss/_variables.scss";




























































































































































































.answer-number-select {
  background-color: #cccccc;
  border-radius: 10px;
  min-width: 40px;
  width: fit-content;
  padding: 0 8px 0 8px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-weight: 600;

  &.selected {
    background-color: cornflowerblue;
    color: white;
  }

  &.selected-editor-mode {
    background-color: #518000;
    color: white;
    opacity: 0.8;
  }
}
