@import "@/scss/_variables.scss";





































































.input-container {
  position: relative;
}
.form-text, .newStyle .form-text {
  height: auto;
  padding: 2.3px 6px;
}
.delete-icon {
  position:absolute;
  right:1px;
  top: -6.3px;
  cursor:pointer;
}
