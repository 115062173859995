@import "@/scss/_variables.scss";
















































.vertical-space-container {
  //background-color: blue;
}
  .vertical-space {
    width: 100%;
  }
  .edit-mode {
    background-color: #e5e5f7;
    opacity: 0.8;
    background: repeating-linear-gradient( -45deg, var(--vform-editor-logo-color), var(--vform-editor-logo-color) 1px, #e5e5f7 1px, transparent 5px );
  }
