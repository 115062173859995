@import "@/scss/_variables.scss";

























































.form-group {
    position: relative;
    margin-right: 15px;
}

label {
    margin-bottom: 0;
}

.fieldset {
    background-color: $panel-title-background-color;
    padding:15px;
    margin-bottom:15px;
}

.label-left {
    float: left;
    margin: 0;
    padding-top: ceil($text-input-padding / 2);
    margin-right: 15px;
}
