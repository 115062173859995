.popup-container {
  color: #fff;
  .form-submit-button {
    height: 25px;
    border-radius: 4px;
    border: solid transparent;
    &:hover {
      border: solid transparent;
    }
    .a {
      border-radius: 4px;
    }
    .a .content {
      font-size: 12px;
      font-weight: 700;
      padding: 2px 8px;
      border-radius: 4px;
      height: 25px;
      line-height: 15px;
      color: #d4d4d5;
    }
  }
}