@import "@/scss/_variables.scss";









































































































































.part-legend {
  padding-left: 15px;
  word-break: break-all;
  .letter {
    font-family: 'Inter bold', sans-serif;
    white-space: nowrap;
    min-width: 2.5rem;
  }
}
