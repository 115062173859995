@import "@/scss/_variables.scss";
















































































































































































































































































.three {
    width: 100%;
    padding-top:100%;
    position:relative;
    background: rgb(221,221,221);
    background: linear-gradient(0deg, rgba(221,221,221,1) 0%, rgba(177,174,166,1) 100%);
    canvas {
        position:absolute;
        top:0;
        left:0;
        height:100%;
        width:100%;
    }
    .icon {
        position:absolute;
        top:50%;
        left:50%;
        -webkit-transform: translate(-50%,-50%);
        transform: translate(-50%,-50%);
        font-size:4.5em;
    }
}
.thumbnail.preview #threeContainer {
    position:absolute;
}
