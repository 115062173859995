@import "@/scss/_variables.scss";































































































































































































.invisible {
  opacity: 0;
  pointer-events: none;
}

.with-icon {
  flex-grow: 1 !important;
}

.wrap-break {
  text-wrap: wrap;
  overflow-wrap: anywhere;
}

.tile-button-image-wrapper {
  height: 90%;
  justify-content: center;
  display: flex;
  align-items: center;
}
