@import "@/scss/_variables.scss";





















































































































































































































































































































































































.text.meta-fixed {
  z-index: 80 !important;
}
.node-subname {
  .node-name-hover {
    display:none;
  }
  &:hover {
    .node-name-hover {
      position: absolute;
      z-index: 60;
      bottom: 0;
      left: 0;
      display: block;
      -webkit-box-shadow: 0 8px 6px -5px black;
      box-shadow: 0 5px 6px -5px black;
    }
  }
}
.lazy-meta-box {
  .hidden {
    position: fixed;
    z-index: 90;
    bottom: 0;
    left: 0;
    display: none;
    -webkit-box-shadow: 0 8px 6px -5px black;
    box-shadow: 0 5px 6px -5px black;
    width: 450px;
    height: 300px;
    overflow-y: scroll;
    &.fixed-meta {
      display: block;
    }
    .meta-fixed-closer, .meta-fixed-editor {
      position: absolute;
      top:5px;
      right: 15px;
      cursor: pointer;
    }
    .meta-fixed-editor {
      top: 25px;
    }
  }
  &:hover {
    .hidden {
      //display: block;
    }
  }
}

