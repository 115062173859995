@import "@/scss/_variables.scss";























































































.user-edit-meta {
  max-width: 400px;

  .vform-label {
    font-size: 0.8rem;
    font-weight: bold;
    color: #91929d;
  }

  .value {
    color: #fff;
    margin-bottom: 1rem;
  }
}
