@import "@/scss/_variables.scss";






























































































































#user-form-standard {
  width: 200px;

  label {
    margin-bottom: 0.1rem;
  }

  .button {
    min-width: auto;
  }

  .form-text {
    border-radius: 3px;
    background-color: $inputcolor;

    &:-webkit-autofill,
    &:-internal-autofill-selected,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      background-color: $inputcolor !important;
      -webkit-box-shadow: 0 0 0 30px $inputcolor inset !important;
      color: #fff !important;
      outline: none;
      -webkit-text-fill-color: #fff !important;
    }

    &:focus {
      outline: none;
    }
  }
}
