@import "@/scss/_variables.scss";
























































































.startedCourseItem {
  background-color: #e36d2e;
}
.doneCourseItem {
  background-color: #479f47;
}
.activeCourseItem {
  background-color: #adadad;
  cursor: auto;
}
.newStyle .activeCourseItem .inner:hover {
  background-color: #adadad;
  color: #000;
  cursor: auto;
}
.progress-bar-course {
  border-radius: 3px;
  width: 75%;
  background-color: #ddd;
  flex-grow: 0;
  .progress {
    border-radius: 3px;
    background-image: repeating-linear-gradient(-45deg, #34383e 0, #626974 1px, #21252b 0, #21252b 4px);
    color: #fff;
  }
}
.steps-progress {
  font-size: 0.8rem;
  margin-top: -2.5px;
  white-space: nowrap;
  padding-left: 5px;
}
