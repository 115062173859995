@import "@/scss/_variables.scss";














































































































.regex-item {
  background-color: var(--vform-editor-ui-secondary-color);
}
