@import "@/scss/_variables.scss";
































































































































































.vform-emphasized {
  .vform-label {
    color: var(--vform-editor-layout-quaternary-color);
  }

  .vform-form-text {
    border: 1px solid var(--vform-editor-layout-quaternary-color);
    color: var(--vform-editor-layout-quaternary-color);
  }
}

.vform-form-text:disabled {
  background-color: transparent;
}
