/*
--------------------
Headings
--------------------
*/
h1 {
  font-weight: normal;
  font-size: 2.2em;
}
h2 {
  font-weight: normal;
  font-size: 1.3em;
  text-transform: uppercase;
}
h3 {
  font-weight: normal;
  font-size: 1.3em;
  text-transform: uppercase;
}
h4 {
  font-weight: bold;
  font-size: 1.1em;
}
h6 {
  font-weight: bold;
  font-size: 1em;
  margin-bottom: 0px;
  font-family:'DIN Pro Bold', sans-serif;
}
.box-title {
  background-color: #171615;
  border-top: 1px solid #282d35;
  border-bottom: 1px solid black;
  padding:8px;
  border-radius: 4px;
  margin-bottom:0;
  width:100%;
}
p {
  margin-bottom:0;
}
p.smaller {
  font-size:0.85em;
}
p.larger {
  font-size:1.2rem;
}

/*
--------------------
Links
--------------------
*/
a {
  color: $textcolor-bright;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;

  &:hover {
    text-decoration: none;
    color: darken($textcolor-bright, 20%);
  }
}
.links-highlighted a {
  color: $highlight;
  &:hover {
    color: lighten($highlight, 10%);
  }
}

/*
--------------------
App
--------------------
*/
.app-inner {
  height: 100%;
  //width: 100%;
  min-height: 100vh;
  font-family: 'DIN Pro Light', sans-serif;
  &.staging {
    .page-title {
      background: $stagingColor;
      background: -webkit-gradient(linear, right top, left top, from($stagingColor), to(darken($stagingColor, 20%)));
      background: linear-gradient(-90deg, $stagingColor 0%, darken($stagingColor, 20%) 100%);
    }
    .page-title-container .spickel {
      background-color: $stagingColor;
    }
  }
}
#vhub {
  background-color: $background;
  color: #fff;
  font-size: 14px;
}
#app {
  min-height: 100vh;
}
body {
  font-size: 15px;
}

/*
--------------------
Basic Layout
--------------------
*/
.top-bar {
  background-color: $pagination-background-color;
  padding: 6px;
  padding-bottom: 3px;
  border-radius: 3px;
}
.content-panel-color {
  background-color: $navbarcolor;
}
.content-panel {
  background-color: $navbarcolor;
  padding-top: 5em;
  padding-bottom: 5em;
  min-height:80vh;
  &.no-padding-top {
    padding-top: 0;
  }
  &.no-margin {
    margin-right: -15px;
    margin-left: -15px;
  }
}
.with-background {
  .content-panel {
    background-color:transparent;
  }
}
.panel-background, .sub-sub-panel-background {
  //background-color: $sub-sub-panel-background-color;
  min-height: 50vh;
  background-color: var(--vform-editor-ui-quinary-color);
  min-height: 50vh;
  border: 1px solid var(--vform-editor-ui-secondary-color);
}
.sub-menu-panel {
  min-height: 153px;
  margin-bottom: -12px;
}
.sub-menu-panel-background {
  height: 38px;
  background-color: $sub-panel-background-color;
  border-bottom: 1px solid #393e4a;
}
.main-container {
  margin-top: 0;
  &.no-margin-top {
    margin-top:0;
  }
}
.main-panel {
  background-color: $navbarcolor;
  padding-bottom: 35px;
  padding-top:28px;
  min-height: 60vh;
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
  .nav-bar #sfx-menu-content {
    position:absolute;
    right:0;
    display:block;
    width: 100%;
    font-size: 0.7rem;
    li {
      display:inline-block;
    }
    > li > a {
      margin-right: 5px;
    }
  }
  .filter {
    position:absolute;
    top:200px;
  }
}

// iPad landscape
@media only screen
and (min-device-width: 1024px)
and (max-device-width: 1024px)
and (min-device-height: 768px)
and (max-device-height: 768px)
and (orientation: landscape)
and (-webkit-min-device-pixel-ratio: 2) {
  .filter {
    position:absolute;
    top:200px;
  }
}
// iPad pro landscape
@media only screen
and (min-device-height: 1366px)
and (max-device-height: 1366px)
and (min-device-width: 1024px)
and (max-device-width: 1024px)
and (orientation: portrait)
and (-webkit-min-device-pixel-ratio: 2) {
  .filter {
    position:absolute;
    top:200px;
  }
}

// iPad portrait
@media only screen
and (min-device-height: 1024px)
and (max-device-height: 1024px)
and (min-device-width: 768px)
and (max-device-width: 768px)
and (orientation: portrait)
and (-webkit-min-device-pixel-ratio: 2) {
  .subtitle-inner {
    flex: 0 0 25% !important;
    max-width: 25% !important;
  }
  .logo-container {
    flex: 0 0 25% !important;
    max-width: 25% !important;
  }
  .nav-container {
    position:absolute;
    top:0;
    right:0;
  }
  #sfx-menu-content {
    position:absolute;
    top: 70px;
  }
  .nav-bar ul > li > a {
    padding: 4px 11px;
  }
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
}

@media (max-width: 1400px) {
  .page-title {
    font-size: 0.9em;
  }
}

@media (min-width: 1400px) {
  #vhub {
    font-size: 14px;
  }
}
@media (min-width: 1600px) {
  #vhub {
    font-size: 14px;
  }
}
