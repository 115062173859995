@import "@/scss/_variables.scss";































































































































































































.info-close {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  z-index: 40;
  color: var(--vform-editor-ui-secondary-color);
}

.popup-play-mode {
  z-index: 35;
}

.info-popup {
  background-color: white;
  position: absolute;
  top: 10%;
  left: 20%;
  height: 80%;
  width: 60%;

  &.with-step-panel {
    left: 10%;
    width: 55%;
  }
}

@keyframes stretch {
  0% {
    transform: scale(0.9);
  }

  50% {
  }

  100% {
    transform: scale(1.3);
  }
}

.fancy-scrollbar {
  /* Track */
  &::-webkit-scrollbar-track {
    background-color: white;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background-color: #5b5b5b;
    border: solid 1px #5b5b5b;
    border-radius: 5px;
    // background-color: $highlight;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background-color: #5b5b5b;
    border: solid 1px #5b5b5b;
    border-radius: 5px;
  }
}

.drop-zone {
  height: 23px;
  position: absolute;
  top: -16px;
  z-index: -1;
  width: 100%;
  left: 0px;
}

.drop-active {
  background-color: var(--vform-editor-accent-color);
  background-clip: content-box;
  opacity: 0.7;
  padding: 6px 0px 8px 0px;
}

.large-drop-zone {
  height: 100px !important;
  position: relative;
  top: 5px;
  -webkit-transform: translateY(-20px);
  transform: translateY(-20px);
  z-index: -1;
  width: 100%;
  //background: gray;
}

.drop-active {
  background-color: var(--vform-editor-accent-color) !important;
  background-clip: content-box;
  opacity: 0.7;
  padding: 6px 0px 8px 0px;
}
