@import "@/scss/_variables.scss";





















































































// inner div
.with-bg {
  padding: 5px 6px;
}

// outer div
.vform-text-display {
  white-space: pre-wrap;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  font-size: 1em;
  margin-bottom: 0.6em;
  &.with-bg {
    width: calc(100% + 20px) !important;
  }
}
