

/**
--------------
TAGS
--------------
*/
div.tag {
  background-color: $tag-background-color;
  display: inline-block;
  //padding: 3px 35px 3px 8px;
  margin-right:8px;
  position:relative;
  word-break: break-all;
  font-size: 0.8rem;
  border-radius: 10px;
  padding: 1px 7px;
  &.drop-shadow {
    -webkit-box-shadow: 4px 4px 6px -5px black;
    box-shadow: 4px 4px 6px -5px black;
  }
  &.removable {
    padding: 1px 32px 1px 12px;
  }
  &.clickable {
    cursor:pointer;
  }
  .icon-right {
    position:absolute;
    right:5px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    opacity:0.8;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
    cursor:pointer;
    &:hover {
      opacity: 1;
    }
  }
}