@import "@/scss/_variables.scss";




















.maintenance-page {
  width: 300px;
  height: 300px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}
.maintenance-msg {

}
