@import "@/scss/_variables.scss";











































































































































.qrcode-container
{
  max-width: 250px;
}
