@import "@/scss/_variables.scss";



































































iframe {
  width: 100%;
  border: 0 transparent;
  display: block;
}
.element-dragging {
  iframe {
    display:none;
  }
  .iframe-draggable-placeholder {
    height: 30px;
    background-color: rgba(255,255,255,0.7);
  }
}
.website-with-background-color {
  background-color: #fff;
}

a{
  color: black;
  text-decoration: underline;
}

.vform-emphasized a{
  color: var(--vform-editor-ui-primary-color);;
}
