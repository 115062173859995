@import "@/scss/_variables.scss";
























































































.content-top-right {
    position:absolute;
    right: 15px;
}
