@import '../fonts.scss'; // this files contains bold the old and new fonts
@import '_variables.scss';
.newStyle {
  font-family: "Inter", serif;

  @import 'page.scss';
  @import 'vformViewer.scss';
  @import 'formElements.scss';
  @import 'vformEditor.scss';
  @import 'popup.scss';

  .app-loading-screen, .form-loading-screen {
    .simple-spinner {
      display: none;
    }
    &.simple {
      .icon {
        display: none;
      }
      .loading-message {
        display:none;
      }
      .simple-spinner {
        display: block;
      }
      background-color: transparent;
    }
    &.hide {
      display:none;
    }
  }

}