@import "@/scss/_variables.scss";
















































































.vform-alignment-button {
  background-color: var(--vform-editor-ui-secondary-color);
  border-radius: 1px;
  margin-left: 1px;
  width: 25px;
  height: 25px;
  position: relative;
  color: #fff;

  .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  &.active {
    background-color: var(--vform-editor-accent-color);
  }
}
