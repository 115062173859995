@import "@/scss/_variables.scss";





























































































































































































































































.checkbox-label-disabled {
  cursor: not-allowed !important;
}

.input-box {
  position: relative;
}

.draw-up {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 18px;
}
