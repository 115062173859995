@import "@/scss/_variables.scss";


























































































































































































































































































































.babycontainer, .babyloncontainer {
  width: 100%;
  height: 100%;
}
canvas {
  width: 100%;
  height: 100%;
}
#stop, #play {
  font-size: 0.9rem;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 5px;
  left: 5px;
  cursor: pointer;
  .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
  &.active, &:hover {
    color: rgba(255,255,255,0.3);
  }
}
#play {
  left: 20px;
}
.sfxfile-selector {
  position: absolute;
  bottom: 5px;
  left: 5px;
  display: inline-block;
  z-index: 55;
  max-width: 200px;
}
