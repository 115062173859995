@import "@/scss/_variables.scss";


















































































































































$slide-height: 70px;
$slide-width: 117px;

.slide-img {
  cursor: pointer;
  width: 125px;
  height: $slide-height;

  &:hover {
    opacity: 0.7;
  }

  border: solid 1px var(--vform-editor-ui-tertiary-color);
  border-radius: 2px;
}

.slide-img, .ghost-slide {
  &.selected {
    outline: solid 2px var(--vform-editor-accent-color);
    border: solid 1px var(--vform-editor-accent-color);
    border-radius: 2px;
  }
}

.thumb-img-container {
  position: relative;
  transition: opacity 300ms ease;
  &:hover {
    .slide-img {
      opacity: 0.7;
    }
  }
}

.slide-not-found {
  min-width: $slide-width;
  width: $slide-width;
  height: 120px;
  background: transparent;
  border: solid 1px $highlight !important;
  color: white;
  font-size: 14px;
}

.carousel-step-title {
  line-height: 25px;
  background: var(--vform-editor-ui-quinary-color);
  border-radius: 3px;
  color: #fff;

  &.fade {
    opacity: 0.3;
  }

  position: relative;
  margin: 0 2px 0 2px;
}

.edit-step-title {
  overflow: show;
  position: absolute;
  top: 0;
  width: 400px;
  left: 2px;
  height: 16px;
  z-index: 100;
  background: var(--vform-editor-ui-quinary-color);
  border-radius: 3px;
  line-height: 25px;
}

.thumb-title-active {
  margin-top: 29px !important;
}

.step-title-input {
  height: 22px !important;
  margin: 1px 2px 0 5px !important;
  border: solid 1px white !important;
  background: var(--vform-editor-ui-quinary-color) !important;
}

.label-step-number {
  margin: auto 0 auto 5px !important;
  background: var(--vform-editor-ui-secondary-color) !important;
  padding: 3px;
  line-height: 10px;
  cursor: move;
}

.label-step-name {
  margin: auto 0 auto 5px !important;
  padding: 3px;
  line-height: 10px;
  font-size: 0.7rem;
}
#step-delete-xmark {
  margin-left: auto;
  margin-right: 6px;

  &:hover {
    color: var(--vform-editor-accent-color);
  }
}
.add-step-diamond {
  position: absolute;
  left: -9px;
  top: -0px;
  cursor: pointer;
}

.add-step-diamond:hover {
  left: -10px;
  top: -2px;
}

#diamond {
  background: var(--vform-editor-layout-secondary-color);
  height: 9px;
  text-align: center;
  transform: rotate(45deg);
  border: solid 1px var(--vform-editor-ui-tertiary-color);
  width: 9px;
}

#diamond:hover {
  background: var(--vform-editor-accent-color);
  height: 12px;
  text-align: center;
  transform: rotate(45deg);
  border: solid 1px var(--vform-editor-accent-color);
  width: 12px;
}

.slide-number {
  opacity: 0.6;
  background: var(--vform-editor-ui-quaternary-color);
  color: var(--vform-editor-ui-primary-color);
  width: auto;
  min-width: 20px;
  text-align: center;
  position: absolute;
  top: 50px;
  left: 9px;
}

.transition-adder {
  opacity: 0.6;
  background: var(--vform-editor-ui-quaternary-color);
  position: absolute;
  right: 9px;
  bottom: 1px;
  font-size: 0.8rem;
  z-index: 50;
  padding-left: 4px;
  padding-right: 4px;
  padding-top: 2px;
  border-radius: 2px;
  transition: all 300ms ease;

  &:hover {
    background: #000;
  }
}

.step-title-height {
  height: 25px;
}

.ghost-slide {
  width: $slide-width;
  min-height: $slide-height;
  background-color: var(--vform-editor-ui-quaternary-color);
  border-radius: 3px;
  font-size: 0.8rem;
  padding: 4px;
}
.spinner {
  background-color: #0f0f10;
  border-radius: 2px;
  width: $slide-width;
  height: $slide-height;
  color: #fff;

  .not-found-text {
    font-size: 0.7rem;
    font-weight: bold;
    text-transform: uppercase;
    width: 90%;

    text-align: center;
  }

  .loading-spinner-widget, .not-found-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.slide-inactive {
  opacity: 0.3;
  .slide-img {
    border: 1px solid #000;
  }
}
