@import "@/scss/_variables.scss";






























































































































.local-toaster {
  width: 100%;
  background: var(--vform-editor-ui-secondary-color);
  padding: 10px;
}
.published-list {
  border-top: 1px solid rgba(255,255,255,0.2);
  margin-top: 15px;
  .even {
    background-color: rgba(0,0,0,0.3);
  }
  .odd {
    background-color: rgba(0,0,0,0.1);
  }
  .item {
    padding: 5px;
    border-bottom: 1px solid rgba(255,255,255,0.2);
  }
  .app-link {
    margin-top: 0.1rem;
    max-width: 40%;
  }
}
