@import "@/scss/_variables.scss";
























































































































































































































































































































































































































































































































































































































.dataset {
    .pagination {
        ul {
            padding-left: 0;
            margin-left: 0;
        }
    }
}
.button-top-right {
    width: 550px;
    text-align: right;
}
.field-list-container {
    display:inline-block;
    position: relative;
    z-index:2;
    .icon-button {
        position:relative;
        z-index: 2;
    }
}
.field-list {
    position:absolute;
    top:0;
    left:0;
    z-index:0;
    width: 300px;
    background-color: $table-header-darker-color;
    padding:8px;
    padding-top:50px;
    .icon {
        padding:4px;
        margin-right:6px;
        background-color: lighten($table-header-darker-color, 10%);
        -webkit-transition: all 300ms ease;
        transition: all 300ms ease;
        cursor:pointer;
        &:hover {
            background-color: $highlight;
        }
    }
}

//transition vue toggling row
.togglerow-enter-active, .togglerow-leave-active {
    transition: all .2s ease-out;
    div {
        max-height: 100px;
    }
}
.togglerow-enter, .togglerow-leave-to {
    div {
        max-height: 0;
    }
}

//transition vue toggling visibility toggler
.toggle-field-list-enter-active, .toggle-field-list-leave-active {
    transition: all .3s ease-out;
    width: 300px;
    max-height: 100vh;
    overflow:visible;
}
.toggle-field-list-enter, .toggle-field-list-leave-to {
    width: 0;
    overflow:hidden;
    max-height:0;
}

.table-filler {
    min-height:20px;
}
.dataset {
    width: 100%;
}
.dataset-container {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
}
.dataset-table {
    table-layout: auto;
    position:relative;
    .header {
        background-color: $table-header-darker-color;
    }
    th {
        position: -webkit-sticky; /* Safari */
        position: sticky;
        top: 0;
        z-index:2;
        background-color: $table-header-darker-color;
    }

    tr {
        td, th {
            border: 1px solid $navbarcolor;
            min-width: 120px;
            padding:10px;
        }
        th:first-child {
            min-width: auto;
            padding-left: 8px;
            width: 70px;
        }
        .data-row-delete {
            min-width: auto;
            max-width: none;
            width: 40px;
        }
    }
}
.dataset-content {
    width:100%;
    height:100%;
    cursor:pointer;
}

.dataset-field {
    padding: 8px 0px;
    cursor: pointer;
}

.table-td-overlay {
    .icons {
        position:absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%,-50%);
        transform: translate(-50%,-50%);
    }
    .icon-container {
        padding: 3px;
        margin: 3px;
        cursor:pointer;
        display:inline-block;
        &:hover {
            background-color: darken($highlight, 10%);
        }
    }
}

#fieldEditPopup {
    width: 90vw;
    max-width: 1200px;
    height: 90vh;
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.9);
    padding: 45px;
    z-index: 5;
    color: #fff;

    .close-button {
        position: absolute;
        top: 25px;
        right: 25px;
        font-size: 1.5em;
        cursor: pointer;
    }
}
