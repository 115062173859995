@import "@/scss/_variables.scss";

















































































































































































































































.name-edit {
  position: relative;
  word-break: break-all;
  display: inline-block;
  padding-right: 20px;

  .edit-trigger {
    right: 0;
    position: absolute;
    margin-top: -3px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    cursor: pointer;
  }

}

.form-text {
  width: 100%;
}

.name-placeholder {
  width: 250px;
  height: 40px;
  background-color: var(--vform-editor-ui-secondary-color);
  filter: blur(1px);
}
