@import "@/scss/_variables.scss";




















































































































.element-dragging {
  border: 1px solid var(--vform-editor-gizmos-primary-color);
  background-color: var(--vform-editor-gizmos-primary-color-brighter);
  z-index: 10;
}

.panel-dragging {
  opacity: 0.8;
}

.info-dot-dragging {
  opacity: 0.5;
}
