@import "@/scss/_variables.scss";






















































































































































































































































































.mod-selector {
  width: 200px;
  display: inline-block;
}

.meta-field-filter {
  max-width: 800px;
}

.filter-row {
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);

  &:first-child {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
  }
}

.form-text-dark {
  margin: 0;
  padding: 0;
  font-size: 0.8rem;
}
