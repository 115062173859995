@import "@/scss/_variables.scss";

































































































.form-text {
  border-radius: 4px;
}
.custom-select {
  border-radius: 4px;
}
.ticketing-textarea {
  min-height: 300px;
}
.ticket-form {
  background-color: var(--vform-editor-ui-secondary-color);
  border-radius: 4px;
  box-shadow: -9px 6px 21px 0px rgba(0,0,0,0.75);
  -webkit-box-shadow: -9px 6px 21px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: -9px 6px 21px 0px rgba(0,0,0,0.75);
}
.vform-label {
  color: var(--vform-editor-ui-primary-color);
}
