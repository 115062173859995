@import "@/scss/_variables.scss";


























































































.invisible {
  visibility: hidden;
  height:0;
  overflow:hidden;
}
.form-switch {
  border-radius: 3px;
  background-color: var(--vform-editor-layout-secondary-color);
  color: #fff;
  position: relative;
  z-index: 0;
  &.open {
    z-index: 505;
  }
}
.any-selector {
  border-radius: 3px;
  background-color: var(--vform-editor-layout-secondary-color);
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  height: auto;
  z-index: 500;
  width: 100%;

  .selected {
    color: #fff;
  }
  .options {
    min-width: 170px;
  }
}
