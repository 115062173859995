@import "@/scss/_variables.scss";




























































































































.form-group {
    position: relative;
}

label {
    margin-bottom: 0;
}

.fieldset {
    background-color: $panel-title-background-color;
    padding:8px;
}

.label-left {
    float: left;
    margin: 0;
    padding-top: ceil($text-input-padding / 2);
    margin-right: 15px;
}
.vector-container {
  .form-group {
    width:32%;
    margin-right:0.5%;
    display:inline-block;
    margin-bottom:0;
    min-width:60px;
    input {
      width: 100%;
    }
  }
}
