@import "@/scss/_variables.scss";
























































































































































































































































































































































































.assembly-caching-handler {
  background-color: var(--vform-editor-ui-secondary-color);
}
