@import "@/scss/_variables.scss";







































































































































































































































































































































































































































































































































































































































.vform-panel {
  background-color: var(--vform-editor-layout-tertiary-color);
  border-radius: 5px;
  position: relative;
  color: var(--vform-editor-ui-tertiary-color);

  label:nth-child(1) {
    color: var(--vform-editor-ui-tertiary-color);
  }
}

.color-remover {
  border-radius: 3px;
}

.panel-remover {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}

.global-panel-toggle {
  position: absolute;
  top: 0;
  right: 40px;
  cursor: pointer;
}

.multi-select {
  //flex-wrap: wrap;
  flex-direction: column;
  max-height: 300px;
  overflow-x: hidden;
  overflow-y: auto;
  border: solid 1px var(--vform-editor-ui-quaternary-color);
}

.multi-select-item {
  min-width: 50px;
  background-color: var(--vform-editor-ui-secondary-color);
  padding: 4px;
  display: inline-flex;
  justify-content: center;
  //margin: 0 4px 4px 0;
  width: 99%;
  color: white;
  justify-content: start;
  user-select: none;

  &:hover {
    background-color: var(--vform-editor-ui-quinary-color) !important;
  }

  &.selected {
    background-color: var(--vform-editor-accent-color);

    &:hover {
      background-color: var(--vform-editor-accent-color-hover) !important;
    }
  }

  &.odd {
    background-color: var(--vform-editor-ui-secondary-color);

    &.selected {
      //background-image: linear-gradient(to right, var(--vform-editor-accent-color) 10%, var(--vform-editor-ui-secondary-color));
      background-color: var(--vform-editor-accent-color);
    }
  }

  &.even {
    background-color: var(--vform-editor-layout-tertiary-color);

    &.selected {
      //background-image: linear-gradient(to right, var(--vform-editor-accent-color-hover) 10% , var(--vform-editor-ui-quinary-color));
      background-color: var(--vform-editor-accent-color-hover);
    }
  }
}

.panel-title {
  width: 80%;
  color: var(--vform-editor-ui-primary-color) !important;
}

.info-label {
  color: var(--vform-editor-ui-tertiary-color);
  font-size: 0.75rem !important;
}

.text-notransform {
  text-transform: revert !important;
}

.global-inactive {
  opacity: 0.5;
}

