@import 'vformViewerVariables';

#vformlogo {
  padding: 5px;
  .square-image {
    width: 150px;
    height: calc(var(--vform-bottom-bar-height) - 10px);
    padding-top: 0;
    background-color: #fff;
  }
}

.user-id-log-entry {
  background-color: rgba(19, 220, 45, 1);
  color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 3px;
  padding: 0 1px;
  opacity: 0.5;
  font-size: 0.8rem;
  &:hover {
    opacity: 1;
  }
}

/**
-------
vForm Elements
-------
*/
/**
setting the emphasized background
*/
.vform-emphasized {
  background-color: var(--vform-editor-layout-accent-color);
  color: var(--vform-editor-emphasized-font-color) !important;
  padding: 0.5rem 1rem;
}
.vform-error {
  border: 2px solid #ce2c2c !important;
  border-radius: 4px;
}
.language-selector-slideshow {
  background-color: transparent;
  border: 0px solid transparent;
  .custom-select, .custom-select option {
    color: var(--vform-on-dark-color);
    border-color: transparent;
  }
  .custom-select {
    padding: 0 12px;
    border: 0 transparent;
  }
}


/**
Buttons
**/
.vform-slide-control-element {
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 100%;
  width: 100%;
  display: flex;
  margin-bottom: 8px;
  height: auto;
  min-height: 30px; // if the item is smaller, there won't be any space for the text
  position:relative;
  &.vform-tiles {
    img {
      object-fit: contain;
    }
  }
  &.right {
    justify-content: right;
  }
  &.center {
    justify-content: center;
  }
  .inner {
    height: 100%;
    width: 100%;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
    border-radius: 3px;
    border: 1px solid var(--vform-editor-layout-primary-color);
    color: var(--vform-editor-gizmos-layout-primary-color);
    &.left, &.right, &.center {
      flex-grow: 0;
      width: auto;
      flex-basis: auto;
    }
    &.justify {
      width: 100%;
    }
    &:hover {
      border: 1px solid var(--vform-editor-layout-secondary-color);
      background-color: var(--vform-editor-layout-secondary-color);
      color: var(--vform-editor-ui-primary-color);
    }
  }

  .no-button-hover-effect {
    &:hover{
      border: 1px solid var(--vform-editor-layout-primary-color);
      color: var(--vform-editor-gizmos-layout-primary-color);
      background-color: transparent;
    }

  }
  .vform-image {
    height: 100%;
    width: 100%;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
    &.center {
      display: flex;
      justify-content: center;
      .vform-image-preview {
        flex-shrink: 0;
      }
    }
    &.right {
      display: flex;
      justify-content: flex-end;
      .vform-image-preview {
  
      }
    }
  }
  .vform-preview-img {
    flex-grow: 0;
    flex-shrink: 0;
    align-self: center;
    width: 2.5vw;
    height: 1.8vw;
    .square-image {
      background-color: transparent !important;
    }
  }
  .vform-image-preview {
    width: 2.5vw;
    height: 1.8vw;
    padding-top: 0;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 10%;
  }
  .text {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 0;
    &.without-icon {
      flex-grow: 1;
    }
  }
}
.vform-panel-header .vform-slide-control-element {
  ///display:inline-block;
}
.vform-icon-img .square-image {
  padding-top: 64%;
}
.vform-tiles {
  .vform-icon-img {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    margin-top: -5px; // only visual correctino
    .square-image {
      padding-top: 100%;
    }
  }
}

.vform-form-text {
  border-radius: 3px;
  border: 1px solid var(--vform-editor-layout-primary-color);
  background-color: transparent;
  padding-left: 3px;
}
.vform-label {
  text-transform: uppercase;
  color: var(--vform-editor-layout-primary-color);
  font-size: 0.75em;
  margin-top: 0.3em;
  font-weight: bold;
  &.white {
    color: #fff;
  }
  &.medium {
    font-size: 0.8rem;
  }
  &.bigger {
    font-size: 0.9rem;
  }
}

.vform-label-for-input {
  text-transform: uppercase;
  font-size: 0.875em;
  margin-top: 0.3em;
  margin-bottom: 0.3em;
  font-weight: bold;
}

/** Font sizes **/
.font-875em{
  font-size: 0.875em;
}

/**
Emphasized
**/
.vform-emphasized {
  .vform-slide-control-element {
    background-color: var(--vform-highlight-hover-color);
    color: var(--vform-on-dark-color);
    border: 1px solid var(--vform-highlight-hover-color);
    &:hover .inner {
      background-color: var(--vform-editor-layout-accent-color);
      color: var(--vform-on-dark-color);
    }
  }
}

/**
Tiles
**/
.vform-tile-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width:100%;
  column-gap: 1%;
  &.no-top-padding {
    padding-top: 0 !important;
  }
}
.vform-tiles {
  background-color: transparent;
  color: var(--vform-on-bright-color);
  width: 49%;
  flex: 0 0 49%;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  padding-top: 40%;
  position:relative;
  .inner {
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
    position:absolute;
    top:0;
    left:0;
    border: 1px solid var(--vform-editor-layout-primary-color);
    overflow: hidden;
  }
  &:hover .inner {
    background-color: var(--vform-editor-ui-secondary-color);
    color: var(--vform-editor-ui-primary-color);
    border: 1px solid var(--vform-editor-ui-secondary-color);
  }
  .vform-preview-img {
    flex-grow: 0;
    flex-shrink: 0;
    align-self: center;
    width: 3.5vw;
    height: 2.8vw;
    position:absolute;
    top:50%;
    left:50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
  }

  .text {
    align-self: end;
  }
}

/**
Icon with text
This can be used for headings or also for options with an image
*/
.vform-item-with-icon-container {
  display: flex;
  align-items: center;
  .vform-preview-img {
    flex-grow: 0;
    flex-shrink: 0;

  }
  .vform-preview-img .square-image {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 10%;
    display: flex;
  }
  .text {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 70%;
    display: flex;
  }
}

/**
Checkboxes/Multi-Choice/Radiobuttons
*/
.vform-checkbox-label-text {
  font-size: 1em;
}

.vform-checkbox-label.disabled {
  color: #bbb;
}
.vform-checkbox-label {
  margin-left: 2rem;
}
.vform-radiobuttons, .vform-checkboxes {
  font-size: 1em;
}
.vform-checkbox, .vform-single-choice {
  width: 1.2rem;
  height: 1.2rem;
  min-width: 19px;
  border: 1px solid var(--vform-on-bright-color);
  //background-color: var(--vform-highlight-hover-color);
  float:left;
  margin-right: 8px;
  //border: 1px solid transparent;
  position:relative;
  //margin-top:3px;
  .inner {
    width: 90%;
    height: 90%;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    color: #fff;
  }
  &.disabled {
    background-color: #ddd;
    border-color: var(--vform-darker-neutral-color);
    .inner {
      background-color: #ddd;
      border-color: #ddd;
    }
  }
  &.selected, &:hover:not(.no-hover) {
    .inner {
      background-color: var(--vform-highlight-checkbox-active-color);
    }
  }
  &.faulty {
    border: 1px solid red;
    .inner {
      border: 1px solid red;
    }
  }
}

.vform-single-choice {
  border-radius: 50%;
  .inner {
    border-radius: 50%;
  }
}

.vform-emphasized {
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  .vform-checkbox, .vform-single-choice {
    border: 1px solid var(--vform-on-dark-color);
    &.selected, &:hover:not(.no-hover) {
      .inner {
        background-color: var(--vform-on-dark-color);
      }
    }
  }
}


/**
vform Imagedisplay
**/
//$image-height: 250px;
.vform-image {
  width: auto;
  //max-height:$image-height;
  position:relative;
  //background-color: #ddd;
  .vform-image-preview {
    padding-top: 100%;
  }
  &.right {
    //display: inline-block;
    img {
      float: right;
    }
  }
  .loading-spinner-widget, .icon {
    position:absolute;
    top:50%;
    left:50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
  }
}
.vform-image-tiles {
  width: 49%;
  flex: 0 0 49%;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  position:relative;
}

.vform-part-legend {
  width: auto;
  margin-top: 1rem;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;

  h3 {
    text-transform: none;
    font-weight: 700;
    color: #000;

    &.with-bg {
      width: calc(100.5% + 42px);
      margin-left: -21px;
      padding: 1em 21px;
    }
  }

  h3 {
    font-size: 1.3em;
  }
  
  &.emphasized {
    background-color: var(--vform-editor-layout-accent-color);
    color: var(--vform-editor-emphasized-font-color);
    padding: 0.5rem 1rem;
  }

  .title {
    &-left {
      justify-content: start;
    }

    &-center {
      justify-content: center;
    }

    &-right {
      justify-content: flex-end;
    }
  }

  .text {
    &-left {
      align-items: start;
    }

    &-center {
      align-items: center;
    }

    &-right {
      align-items: flex-end;
    }
  }

}

.vform-viewer-editor-overlay {
  position: absolute;
  top: 0;
  left:-2px;
  right: -2px;
  bottom:0;
  background-color: transparent;
  //opacity:0;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  cursor:pointer;
  border: 1px transparent;
  z-index: 1;
  &:hover, &.active {
    border: 1px solid var(--vform-editor-gizmos-primary-color);
    //opacity: 1;
    //background-color: rgba(255,255,255,0);
    background-color: var(--vform-editor-gizmos-primary-color-brighter);
    .editor-overlay-icon {
      opacity: 1;
    }
  }
  .editor-overlay-icon {
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
    opacity: 0;
  }
}
.editor-overlay-icon {
  border: 1px solid var(--vform-editor-gizmos-primary-color);
  background-color: var(--vform-editor-gizmos-primary-color);
  color: var(--vform-viewer-editor-icon-color);
  .icon {
    position: absolute;
    top: 50%;
    left:50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    font-size: 0.9rem !important;
  }
}

/***
Buttons
*/
.vform-button {
  border-radius: 3px;
  border: 1px solid var(--vform-editor-gizmos-primary-color);
  background-color: transparent;
}
.vform-slide-control-element {
  .next-button,
  .prev-button {
    border-radius: 3px;
    border: 1px solid var(--vform-editor-layout-accent-color);
    background-color: var(--vform-editor-layout-accent-color);
    min-width: 40%;
    //cursor: pointer;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
    color: var(--vform-editor-emphasized-font-color);
    padding: 0.75em 1em !important;

    .loading-spinner-widget {
      margin-top: 5px;
    }
    .icon {
      transition: all 300ms ease;
      //margin-top: 1px;
    }
    .text {
      font-size: 1.1em;
    }
    &:hover {
      border: 1px solid var(--vform-highlight-hover-color);
      background-color: var(--vform-highlight-hover-color);
      color: var(--vform-editor-emphasized-font-color);
    }
  }
}

.next-button {
  padding-right: 35px;
  position: relative;
  .icon {
    position: absolute;
    right: 10px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  &:hover {
    .icon {
      //right: 6px;
    }
  }
}
.prev-button {
  padding-left: 35px;
  position: relative;
  .icon {
    position: absolute;
    left: 10px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  &:hover {
    .icon {
      left: 6px;
    }
  }
}

.vform-bottom-bar {
  .form-switch {
    padding: 2px;
    background-color: var(--vform-editor-ui-secondary-color);
    border-color: var(--vform-editor-ui-secondary-color);
    .language-selector {
      min-width: 190px;
      color: var(--vform-editor-ui-primary-color);
      .selected {
        color: var(--vform-editor-ui-primary-color);
      }
    }
  }
}

.BUTTON_ITEM {
  border-radius: 4px;
  font-weight: 700;
  font-size: 1em;
  line-height: 1.2em;
  //padding: 0.5rem 1rem;
}

.simple-button{
  padding: 0.5rem 1rem;
  text-align: center;
}

.tile-button-text {
  position: absolute;
  left: 1rem;
  bottom: 0.5rem;
}

.item-selector-dropdown {
  font-size: 1rem;
  position:relative;
  color: var(--vform-editor-layout-accent-color);
  align-self: start;
  .check {
    visibility: hidden;
    &.visible {
      visibility: visible;
    }
  }
  .up-down {
    position: absolute;
    right: 15px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  .selected {
    cursor:pointer;
    color: var(--vform-editor-layout-accent-color);
  }
  .options {
    position:absolute;
    background-color: var(--vform-editor-layout-secondary-color);
    border: 1px solid rgba(255,255,255,0.3);
    color: var(--vform-editor-layout-quaternary-color);
    -webkit-box-shadow: 0 8px 6px -5px black;
    box-shadow: 0 5px 6px -5px black;
    padding: 2px;
    max-height: 200px;
    overflow-y: scroll;
    overflow-x:hidden;
    cursor: pointer;
    z-index: 5;
    left:0;
    width: 100%;
    border-radius: 4px;
    &.closed {
      z-index: 0;
      height: 0;
      overflow: hidden;
      opacity: 0;
    }
    div {
      padding: 2px;
      &:hover {
        background-color: var(--vform-editor-ui-secondary-color);
        //color: var(--vform-editor-layout-accent-color);
      }
    }
    &.top {
      bottom: 0;
    }
    &.bottom {
      top: 0;
    }
  }
}

.vform-text-option {
  cursor: pointer;
  background-color: var(--vform-editor-ui-secondary-color);
  margin-left: 1px;
  position: relative;
  width: fit-content;
  border-radius: 3px;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  &.left {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }
  &.right {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
  &.middle {
    border-radius: 0;
  }
  p {
    margin: 0;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.7rem;
    padding: 5px;
  }
  &.active {
    background-color: var(--vform-editor-accent-color);
  }
}

// iPad landscape
@media only screen
and (min-width: 1280px)
and (max-width: 1280px)
and (min-height: 800px)
and (max-height: 800px)
and (orientation: landscape)
and (-webkit-min-device-pixel-ratio: 2) {
  body {
    font-size: 14px;
  }
}
