@import "@/scss/_variables.scss";










































































































































.download {
  .content {
    height:auto !important;
  }
  &> div {
    //margin-right:50%;
    margin-right: 8px;
  }

}
.download .button {
    width:210px;
  .smaller {
    max-width: 130px;
    text-transform: none;
    &.lower {
      margin-bottom: 15px;
    }
  }
}
#versiontext {
  font-weight: bold;
  font-family: "DIN Pro Bold", sans-serif;
  font-size: 1.1rem;
  line-height: 1.4rem;
  .highlight a {
    color: $highlight;
    cursor: pointer;
    &:hover {
      color: lighten($highlight, 10%);
    }
  }
}
#version {
  float:left;
  display: inline-block;
  font-family: "DIN Pro Bold", sans-serif;
  font-size: 4.5rem;
  line-height: 4.5rem;
  font-weight: bold;
  color: #0066FF;
  -webkit-text-fill-color: #0066FF; /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #b1bbd7;
  text-shadow: rgba(31, 111, 232, 0.9) 0px 0px 32px;

}
