@import "@/scss/_variables.scss";

























































































.container-row {
   background-color: #343536;
   position:relative;
}
.container-category {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #4e4f50;
}
.devops-container-container {
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  margin-right: 15px;
  padding-top: 80px;
}
.devops-container {
  background-color: #ddd;
  border-radius: 4px;
  position:relative;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  padding-top: 80px;
  .header {
    position:absolute;
    top:0;
    left: 0;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    width: 100%;
    height: 80px;
    padding: 15px;
    color: #000;
    background-color: #bbb;
  }
}
.inbox .header {
  background-color: var(--ccdefault-ticketing-container-header-color);
  color: #fff;
}
.devops-container-closed {
  display: flex;
  flex-direction: column;
  width: 110px;
}

.opener-closer, .reload {
  position:absolute;
  top: -25px;
  left:0;
  background-color: var(--ccdefault-ticketing-container-header-color);
  width: 30px;
  height: 30px;
  border-radius: 4px;
  cursor:pointer;
   -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  border: 1px solid var(--ccdefault-ticketing-container-header-color-hover);
  &:hover {
    background-color: var(--ccdefault-ticketing-container-header-color-hover);
  }
  .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    color: #fff;
  }
}
.reload {
  top: 0;
  right:0;
  left: auto;
}
