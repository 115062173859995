@import "@/scss/_variables.scss";



















































































































































































.course-navigation {
  min-height: 20px;
  font-size: 0.9rem;
}

.category-name {
  color: #fff;
  transition: all 300ms ease;
  background-color: #5d7366;
  &.is-clickable {
    cursor: pointer;
    background-color: #354044;
    &:hover {
      background-color: #ec7412;
    }
  }
  &.startedCourseItem {
    background-color: #e36d2e;
  }
  &.doneCourseItem {
    background-color: #479f47;
  }
}
