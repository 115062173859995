@import "@/scss/_variables.scss";

















































































































































































































































































































































































































































































































































































.qr-code-thumbnail > a {
  font-size: 12px;
}
