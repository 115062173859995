/* #### Generated By: http://www.cufonfonts.com #### */
@font-face {
  font-family: 'DIN Pro';
  font-style: normal;
  font-weight: normal;
  src: local('DIN Pro'), url('../font/dinpro/DINPro.woff') format('woff');
}
@font-face {
  font-family: 'DIN Pro Italic';
  font-style: normal;
  font-weight: normal;
  src: local('DIN Pro Italic'), url('../font/dinpro/DINPro-Italic.woff') format('woff');
}
@font-face {
  font-family: 'DIN Pro Light';
  font-style: normal;
  font-weight: normal;
  src: local('DIN Pro Light'), url('../font/dinpro/DINPro-Light.woff') format('woff');
}
@font-face {
  font-family: 'DIN Pro Light Italic';
  font-style: normal;
  font-weight: normal;
  src: local('DIN Pro Light Italic'), url('../font/dinpro/DINPro-LightItalic.woff') format('woff');
}
@font-face {
  font-family: 'DIN Pro Medium';
  font-style: normal;
  font-weight: normal;
  src: local('DIN Pro Medium'), url('../font/dinpro/DINPro-Medium.woff') format('woff');
}
@font-face {
  font-family: 'DIN Pro Bold';
  font-style: normal;
  font-weight: normal;
  src: local('DIN Pro Bold'), url('../font/dinpro/DINPro-Bold.woff') format('woff');
}
@font-face {
  font-family: 'DIN Pro Bold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('DIN Pro Bold Italic'), url('../font/dinpro/DINPro-BoldItalic.woff') format('woff');
}
@font-face {
  font-family: 'DIN Pro Black';
  font-style: normal;
  font-weight: normal;
  src: local('DIN Pro Black'), url('../font/dinpro/DINPro-Black.woff') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: normal;
  src: local('Inter'), url('../font/inter/Inter-Regular.woff') format('woff');
}
@font-face {
  font-family: 'Inter bold';
  font-style: normal;
  font-weight: bold;
  src: local('Inter'), url('../font/inter/Inter-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'Inter light';
  font-style: normal;
  font-weight: light;
  src: local('Inter'), url('../font/inter/Inter-Light.ttf') format('truetype');
}
@font-face {
  font-family: 'Inter medium';
  font-style: normal;
  font-weight: medium;
  src: local('Inter'), url('../font/inter/Inter-Medium.ttf') format('truetype');
}