@import "@/scss/_variables.scss";


























































































.tile-container {
  display: flex;
  width: 100%;
  flex-wrap: wrap;

  &.viewAndEdit {
    .tile {
      cursor: pointer;
      border-radius: 2px;

      &.active, &:hover {
        background-color: $tile-button-background-active;
        -moz-box-shadow: none;
        -webkit-box-shadow: none;
        box-shadow: none;
        border: 1px solid $tile-active-border-color;

        div {
          opacity: 1;
        }
      }
    }
  }

  .tile {
    margin-bottom: -1px;
    margin-right: -1px;
    flex: 0 0 50%;
    max-width: 50%;
    background-color: $tile-button-background;
    border: 1px solid $tile-border-color;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
    -moz-box-shadow: inset 0 0 10px #222;
    -webkit-box-shadow: inset 0 0 10px #222;
    box-shadow: inset 0 0 10px #222;
    cursor: not-allowed;

    div {
      opacity: 0.3;
    }

    &.active {
      background-color: $tile-button-background-active;
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      border: 1px solid $tile-active-border-color;

      div {
        opacity: 1;
      }
    }
  }

  &.rows-3 {
    .tile {
      flex: 0 0 33%;
      max-width: 33%;
    }
  }

  &.rows-4 {
    .tile {
      flex: 0 0 25%;
      max-width: 25%;
    }
  }

  div {
    text-align: center;
    padding: 1px 0;
  }

  &.compact {
    .tile {
      position: relative;
      padding-left: 32px;
    }

    .icon-container {
      position: absolute;
      left: 7px;
      top: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
    }

    div {
      text-align: left;
    }
  }

  &.iconsOnly {
    .icon-container {
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }
  }
}
