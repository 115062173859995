@import "@/scss/_variables.scss";




















































.view-switcher {
    display:inline-flex;
}
.view-option {
    background-color: #2a2a2a;
    max-width:35px;
    padding:5px 8px;
    text-align: center;
    font-size:1em;
    border-right: 1px solid #000;
    cursor:pointer;
    &:last-child {
        border-right: 0;
    }
    &:hover, &.active {
        background-color: $highlight;
    }
}
