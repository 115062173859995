@import "@/scss/_variables.scss";

































































































































































































































































































































































































































.sfx-icon {
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
}
