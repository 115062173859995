/**
These are the basic styles for the newstyle version
*/

h1 {
  color: var(--ccdefault-h1-font-color);
  font-size: 1.7rem;
  font-weight:light;
  font-family: "Inter bold", serif;
  &.bright {
    color: #fff;
  }
}

h2 {
  font-size: 1.4rem;
}

h3 {
  font-size: 1.1rem;
  &.title-addition {
    font-family: "Inter light", serif;
    font-size:0.9rem;
    color: var(--ccdefault-h-addition-font-color);
    text-transform: none;
  }
}